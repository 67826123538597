// Api
import AxiosClient from '@api/axiosClient';
// Models
import { ApprovalData, IOnboardingPostInformation, ISaveStepData } from '@models/Onboarding';
// Urls
import { urls } from './urls';

export const apiPostOnboardingStatus = <T>(data: IOnboardingPostInformation): Promise<T> =>
    AxiosClient.post(urls.onboarding.status, data);

export const apiSaveApproval = <T>(data: ApprovalData): Promise<T> => AxiosClient.post(urls.onboarding.approval, data);

export const apiApproveDataProcessingDocuments = <T>(data: ApprovalData): Promise<T> =>
    AxiosClient.post(urls.onboarding.apporveDataProcessing, data);

export const apiSaveApprovalElectronicPayroll = <T>(data: ApprovalData): Promise<T> =>
    AxiosClient.post(urls.onboarding.approvalElectronicPayroll, data);

export const apiPostSaveStep = <T>(data: ISaveStepData): Promise<T> => AxiosClient.post(urls.onboarding.saveStep, data);

export const apiPostApprovalStatus = <T>(data: IOnboardingPostInformation): Promise<T> =>
    AxiosClient.post(urls.onboarding.approvalStatus, data);
