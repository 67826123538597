// Libraries
import * as Yup from 'yup';
// Models
import { IInitEmployee, IValidationTypeFunder } from '@models/Employee';
import { IValidateSalary } from '../models/Employee';
// Hooks
import { useAppSelector } from '@hooks/redux';
import { RootState } from '@redux/rootReducer';
import { useValidateDocumentNumber } from '@hooks/useValidateDocumentNumber';
// Constants
import {
    CD,
    INTEGRAL_SALARY,
    PA,
    TERM,
    contractTerm,
    SalaryTypesId,
    contractType,
    contractSENA,
    contractUniversityPractice,
    YES_SPANISH,
    TypesPayMethodWithoutData,
    EMPLOYEE_PAYMENT_DATA_MESSAGES,
} from '@pages/employees/constants/Employees';
import {
    CUSTOM_VALIDATE_EXCEPTION_SUB_TYPE_CONTRIBUTOR,
    CUSTOM_VALIDATE_SUB_TYPE_CONTRIBUTOR,
    CUSTOM_VALIDATE_TYPE_FUNDER,
} from '../components/create-employee/components/step-two/constants/StepTwo';
import { BANK_OTHER_ID } from '@constants/Utils';
// Utils
import { parseValue } from '@utils/Number';
import { IDynamicData } from '../../../models/Utils';
import { regexContainNumber, validateEmail } from '@utils/Text';
import { ICustomValidationSubContributor, TCustomValidationStepTwo } from '../components/models/EmployeeComponents';

export const useValidate = (): {
    employeeSchema: Yup.SchemaOf<IInitEmployee>;
    validatePayMethod: (values: IInitEmployee) => IValidateSalary | object;
    validateValuesStepOne: (values: IInitEmployee) => boolean;
    validateDocumentNumber: (value: string, documentTypeId: string) => IValidateSalary | object;
    validateContractTerm: (contract_type_id: string) => string | boolean;
    validateIntegralSalary: (values: IInitEmployee) => IValidateSalary | object;
    validateIntegralSalaryDate: (values: IInitEmployee) => IValidateSalary | object;
    validateCustomForm: (values: IInitEmployee) => IValidateSalary | object;
    validateSelectionContractTerm: (contract_term_id: string) => boolean;
    validateTypeDocumentNumber: (documentTypeId: string) => boolean;
    validateValuesStepTwo: (values: IInitEmployee) => boolean;
    validateValuesStepThree: (values: IInitEmployee) => boolean;
} => {
    const {
        utils: { document_type, contract_type, contract_term, salary_type, integral_minimum_wage },
        company: {
            information: { pila_user },
        },
    } = useAppSelector((state: RootState) => state);
    const { validateDocumentNumber } = useValidateDocumentNumber();

    /**
     * Schema validation from form employee
     */
    const employeeSchema = Yup.object().shape({
        id: Yup.string().optional(),
        names: Yup.string().required('* Campo requerido'),
        second_name: Yup.string().optional(),
        last_names: Yup.string().required('* Campo requerido'),
        second_last_name: Yup.string().optional(),
        document_type_id: Yup.string().required('* Campo requerido'),
        document_number: Yup.string().required('* Campo requerido'),
        birthdate: Yup.string().nullable().required('* Campo requerido'),
        email: Yup.string().email('* Correo electrónico invalido').required('* Campo requerido'),
        colombian_resident_abroad: Yup.boolean().optional(),
        address: Yup.string().required('* Campo requerido'),
        filing_date_in_ext: Yup.string().optional(),
        foreigner_not_required_to_pay_pension_contributions: Yup.bool().optional(),
        first_start_contract_date: Yup.string().optional(),
        arl_id: Yup.string().optional(),
        exempt_from_parafiscal_taxes: Yup.boolean().required('* Campo requerido'),
        department_id: Yup.string().required('* Campo requerido'),
        position_id: Yup.string().required('* Campo requerido'),
        cost_center_id: Yup.string().required('* Campo requerido'),
        cost_center_name: Yup.string().optional(),
        pension_fund_id: Yup.string().optional(),
        cesantias_fund_id: Yup.string().optional(),
        eps_id: Yup.string().optional(),
        country_id: Yup.string().required('* Campo requerido'),
        ciiu_code: Yup.string().optional(),
        name_branch: Yup.string().optional(),
        work_center: Yup.string().optional(),
        state_id: Yup.string().required('* Campo requerido'),
        city_id: Yup.string().required('* Campo requerido'),
        postal_code: Yup.string().optional(),
        phone: Yup.string().optional(),
        employer_id: Yup.string().optional(),
        type_funder_id: Yup.string().required('* Campo requerido'),
        contributor_subtype: Yup.string().required('* Campo requerido'),
        compensation_box_id: Yup.string().optional(),
        contract_type_id: Yup.string().required('* Campo requerido'),
        contract_term_id: Yup.string().required('* Campo requerido'),
        start_date: Yup.string().required('* Campo requerido').nullable(),
        salary: Yup.string().optional(),
        transportation_assistance: Yup.boolean().optional(),
        finish_date: Yup.string().optional(),
        salary_type_id: Yup.string().optional(),
        permanent_salary_change: Yup.boolean().optional(),
        start_date_new_salary: Yup.string().optional(),
        finish_date_new_salary: Yup.string().optional(),
        permanent_salary_change_id: Yup.boolean().optional(),
        risk_class_id: Yup.string().optional(),
        high_risk_rate_pension: Yup.boolean().required('* Campo requerido'),
        has_salary_endowment: Yup.string().required('* Campo requerido'),
        salary_endowment_value: Yup.string().optional(),
        has_sustaining_support: Yup.boolean().required(),
        payment_method_id: Yup.string().required('* Campo requerido'),
        bank_id: Yup.string().nullable(),
        bank_account_type_id: Yup.string().nullable(),
        account_number: Yup.string().optional(),
        optional_ccf_contribution: Yup.boolean().optional(),
        old_salary: Yup.string().optional(),
        start_Integral_Salary: Yup.string().optional(),
    });

    /**
     * Validate fields step one
     * @param {IInitEmployee} values - Values form
     * @returns {boolean} - state to validate
     */
    const validateValuesStepOne = (values: IInitEmployee): boolean => {
        const isValid = validateDocumentNumber(values.document_number, values.document_type_id);
        const isValidNames = validateNames(values);
        const isValidBirthdate = validateBirthdate(values);
        const errorExt = validateFalingDateExt(values);
        const email = validateEmail(values?.email);

        return (
            !!values?.names &&
            !!values?.last_names &&
            !!values?.document_type_id &&
            !!values?.document_number &&
            Object.keys(isValid || {}).length === 0 &&
            Object.keys(isValidNames || {}).length === 0 &&
            Object.keys(isValidBirthdate || {}).length === 0 &&
            Object.keys(errorExt || {}).length === 0 &&
            !!values?.birthdate &&
            !!email &&
            !!values?.position_id &&
            !!values?.department_id &&
            !!values?.cost_center_id
        );
    };

    /**
     * Validate permanent salary
     * @param {IInitEmployee} values - values employee
     * @returns {IValidateSalary | object} error from validation
     */
    const validatePermanentSalary = (values: IInitEmployee): IValidateSalary | object => {
        const { permanent_salary_change_id, start_date_new_salary } = values;
        const errors: IValidateSalary = {};

        if ((permanent_salary_change_id ?? false) && !start_date_new_salary) {
            errors.start_date_new_salary = '* Campo requerido';
        }

        return errors;
    };

    /**
     * Validate fields step three
     * @param {IInitEmployee} values - Values form
     * @returns {boolean} - state to validate
     */
    const validateValuesStepThree = (values: IInitEmployee): boolean => {
        const validateContractTerm = validateSelectionContractTerm(values?.contract_term_id) ? true : !!values?.finish_date;
        const validateEndowmentValue = values.has_salary_endowment === YES_SPANISH ? !!values.salary_endowment_value : true;
        const isValidateSalaryType = validateSalaryType(values);
        const isValidateIntegralSalary = validateIntegralSalary(values);
        const isIntegralSalaryDateWellRegistered = validateIntegralSalaryDate(values);
        const validatePermanentSalaryErrors = validatePermanentSalary(values);
        return (
            !!values?.contract_term_id &&
            !!values?.contract_type_id &&
            !!values?.start_date &&
            !!values?.has_salary_endowment &&
            validateContractTerm &&
            validateEndowmentValue &&
            Object.keys(isIntegralSalaryDateWellRegistered || {}).length === 0 &&
            Object.keys(isValidateSalaryType || {}).length === 0 &&
            Object.keys(isValidateIntegralSalary || {}).length === 0 &&
            Object.keys(validatePermanentSalaryErrors || {}).length === 0
        );
    };

    /**
     * Validate fields step two
     * @param {IInitEmployee} values - Values form
     * @returns {boolean} - state to validate
     */
    const validateValuesStepTwo = (values: IInitEmployee): boolean => {
        const isValidTypeFunders = validateTypeFunders(values);
        const isValidTaxPayer = validateTaxPayer(values);
        return Object.keys(isValidTypeFunders || {}).length === 0 && Object.keys(isValidTaxPayer || {}).length === 0;
    };

    /**
     * Validate type to selected from input
     * @param {string} documentTypeId - Id document type
     * @returns {boolean} to change type input
     */
    const validateTypeDocumentNumber = (documentTypeId: string): boolean => {
        const currentType = document_type.find(type => type.id === documentTypeId);
        return !!((currentType && currentType.code === PA) || (currentType && currentType.code === CD));
    };

    /**
     * Validate contract term vy contract type id
     * @param {string} contract_type_id - Id contract type selected
     * @returns {boolean} state to change list to show user
     */
    const validateContractTerm = (contract_type_id: string): string | boolean => {
        const elementFind = contract_type?.find(type => type?.id === contract_type_id && type?.contractTerm);
        const termId = contract_term?.find(element => element.name === TERM)?.id;
        if (elementFind && elementFind?.id) {
            return false;
        } else {
            return termId || true;
        }
    };

    /**
     * Validate contract term to show finish date
     * @param {string} contract_term_id - Id contract term
     * @returns {boolean} state to show finish date
     */
    const validateSelectionContractTerm = (contract_term_id: string): boolean => {
        const elementFind = contract_term?.find(contract => contract.id === contract_term_id);
        if (elementFind) {
            return !contractTerm?.some((item: string) => item === elementFind.name);
        } else {
            return true;
        }
    };

    /**
     * Validate value salary
     * @param {IInitEmployee} values - values employee
     * @returns {IValidateSalary | object} error from validation
     */
    const validateIntegralSalary = (values: IInitEmployee): IValidateSalary | object => {
        const { salary_type_id, contract_type_id, salary } = values;
        const currentType = salary_type?.find(type => type.id === salary_type_id)?.name;
        const currentContractType = contract_type?.find(type => type.id === contract_type_id)?.name;
        const errors: IValidateSalary = {};

        if (currentType === INTEGRAL_SALARY && parseValue(salary || '') < integral_minimum_wage * 13) {
            errors.salary = 'Para el tipo de salario seleccionado se aceptan valores iguales a 13 SMLV o más';
        }

        if (currentContractType === contractType[3] || currentContractType === contractType[4]) {
            if (Number(salary || '') === 0) {
                return {};
            }
        }

        return errors;
    };

    /**
     * Validate finish date
     * @param {string} contractTermId - contract term id
     * @param {string} value - value finish date
     * @returns {IValidateSalary | object} error from validation
     */
    const validateFinishDate = (contractTermId: string, value: string | null): IValidateSalary | object => {
        const listTerms: IDynamicData[] = [];
        const errors: IValidateSalary = {};
        contract_term.map(term => {
            contractTerm.map(element => {
                if (term.name === element) {
                    listTerms.push(term);
                }
            });
        });

        if (!value && listTerms.find(term => term.id === contractTermId)) {
            errors.finish_date = '* Campo requerido';
        }
        return errors;
    };

    /**
     * Validate type funder
     * @param {IInitEmployee} values - values employee
     * @returns {IValidateSalary | object} error from validation
     */
    const validateTypeFunders = (values: IInitEmployee): IValidateSalary | object => {
        const { type_funder_id, contributor_subtype } = values;
        let errors: IValidateSalary = {};
        const valuesCustom: IValidationTypeFunder = {
            arl_id: values.arl_id,
            risk_class_id: values.risk_class_id,
            eps_id: values.eps_id,
            pension_fund_id: values.pension_fund_id,
            cesantias_fund_id: values.cesantias_fund_id,
            type_funder_id: values.type_funder_id,
            contributor_subtype: values.contributor_subtype,
            compensation_box_id: values.compensation_box_id,
        };

        const administratorsIdCustomValidation = CUSTOM_VALIDATE_TYPE_FUNDER.filter(x => x.administratorId !== 'Others').map(
            c => c.administratorId
        );

        const subTypeContributors = CUSTOM_VALIDATE_SUB_TYPE_CONTRIBUTOR.map(x => x.administratorId);

        // Valid fields to type_funder
        if (administratorsIdCustomValidation.every(x => x != type_funder_id)) {
            CustomValitionTypeFunder(valuesCustom, 'Others', errors, CUSTOM_VALIDATE_TYPE_FUNDER);
        } else {
            CustomValitionTypeFunder(valuesCustom, type_funder_id, errors, CUSTOM_VALIDATE_TYPE_FUNDER);
        }

        // If the user select a contributor_sub_type with custom validation
        if (subTypeContributors.some(x => x === contributor_subtype)) {
            errors = {};
            CustomValitionTypeFunder(valuesCustom, contributor_subtype, errors, CUSTOM_VALIDATE_SUB_TYPE_CONTRIBUTOR);
        }

        // If type_funder and contributor subtype have custom validation but this validation create conflict, this case handle how exception
        const existException = CUSTOM_VALIDATE_EXCEPTION_SUB_TYPE_CONTRIBUTOR.some(
            c => c.administratorId === type_funder_id && c.subContributorIds.some(s => s === contributor_subtype)
        );

        if (existException) {
            errors = {};
            CustomValitionTypeFunderSubContributorException(
                valuesCustom,
                type_funder_id,
                contributor_subtype,
                errors,
                CUSTOM_VALIDATE_EXCEPTION_SUB_TYPE_CONTRIBUTOR
            );
        }

        return errors;
    };

    /**
     * Method to execute custom validate to step two
     * @param values values form
     * @param typeFunder administrator_Id
     * @param errors errors form
     * @param validations field to validate
     * @returns
     */
    const CustomValitionTypeFunder = (
        values: IValidationTypeFunder,
        typeFunder: string,
        errors: IValidateSalary,
        validations: TCustomValidationStepTwo[]
    ): void => {
        const customValidation = validations.find(c => c.administratorId === typeFunder);
        if (!customValidation) return;

        const nameProperties = Object.getOwnPropertyNames(values);
        for (const prop of customValidation.propertiesValidate) {
            if (!nameProperties.some(name => name === prop.name)) continue;
            if (values[prop.name] && values[prop.name] != '') continue;

            errors[prop.name] = customValidation.messageError ?? '';
        }
    };

    /**
     * Method to execute custom validate to step two when the combination bettwen typefunder and subContributor exits exception
     * @param values values form
     * @param typeFunder administrator_Id
     * @param subContributor subContributor_Id
     * @param errors errors form
     * @param validations field to validate
     * @returns
     */
    const CustomValitionTypeFunderSubContributorException = (
        values: IValidationTypeFunder,
        typeFunder: string,
        subContributor: string,
        errors: IValidateSalary,
        validations: ICustomValidationSubContributor[]
    ): void => {
        const customValidation = validations.find(
            c => c.administratorId === typeFunder && c.subContributorIds.some(s => s === subContributor)
        );
        if (!customValidation) return;

        const nameProperties = Object.getOwnPropertyNames(values);
        for (const prop of customValidation.propertiesValidate) {
            if (!nameProperties.some(name => name === prop.name)) continue;
            if (values[prop.name] && values[prop.name] != '') continue;

            errors[prop.name] = customValidation.messageError ?? '';
        }
    };

    /**
     * Validate birthdate
     * @param {IInitEmployee} values - values employee
     * @returns {IValidateSalary | object} error from validation
     */
    const validateBirthdate = (values: IInitEmployee): IValidateSalary | object => {
        const { birthdate } = values;
        const errors: IValidateSalary = {};

        const today = new Date();
        const dateFifteenYearsAgoYearsAgo = new Date(today.getFullYear() - 15, today.getMonth(), today.getDate());

        if (!(new Date(birthdate) <= dateFifteenYearsAgoYearsAgo)) {
            errors.birthdate = 'Debe cumplir con 15 años de edad';
        }

        return errors;
    };
    /**
     * Validate names
     * @param {IInitEmployee} values - values employee
     * @returns {IValidateSalary | object} error from validation
     */
    const validateNames = (values: IInitEmployee): IValidateSalary | object => {
        const { names, last_names, second_name, second_last_name } = values;
        const errors: IValidateSalary = {};

        if (regexContainNumber(names)) {
            errors.names = '* No debe contener números';
        }
        if (regexContainNumber(last_names)) {
            errors.last_names = '* No debe contener números';
        }
        if (regexContainNumber(second_name || '')) {
            errors.second_name = '* No debe contener números';
        }
        if (regexContainNumber(second_last_name || '')) {
            errors.second_last_name = '* No debe contener números';
        }

        return errors;
    };

    /**
     * Validate tax payer
     * @param {IInitEmployee} values - values employee
     * @returns {IValidateSalary | object} error from validation
     */
    const validateTaxPayer = (values: IInitEmployee): IValidateSalary | object => {
        const { name_branch, work_center } = values;
        const errors: IValidateSalary = {};

        if (pila_user) {
            if (!name_branch) {
                errors.name_branch = '* Campo requerido';
            }
            if (!work_center) {
                errors.work_center = '* Campo requerido';
            }
        }

        return errors;
    };

    /**
     * Validate endowment
     * @param {IInitEmployee} values - values employee
     * @returns {IValidateSalary | object} error from validation
     */
    const validateEndowment = (values: IInitEmployee): IValidateSalary | object => {
        const { salary_endowment_value, has_salary_endowment, contract_type_id, salary } = values;
        const errors: IValidateSalary = {};

        if (has_salary_endowment === 'SI') {
            if (!salary_endowment_value && contract_type_id !== contractSENA && contract_type_id !== contractUniversityPractice) {
                errors.salary_endowment_value = '* Campo requerido';
            }

            if (salary_endowment_value === '0') {
                errors.salary_endowment_value = 'El valor debe ser mayor a 0';
            }
        }

        if (contract_type_id === contractSENA) {
            if (!salary) {
                errors.salary = '* Campo requerido';
            } else if (salary === '0') {
                errors.salary = 'El valor debe ser mayor a 0';
            }
        }

        return errors;
    };

    /**
     * Validate endowment
     * @param {IInitEmployee} values - values employee
     * @returns {IValidateSalary | object} error from validation
     */

    const isValidAccountNumber = (account_number: string): boolean => {
        const accountNumberRegex = /^\d{7,20}$/;
        return accountNumberRegex.test(account_number);
    };

    const getAccountNumberErrorMessage = (account_number: string): string | null => {
        if (!account_number) {
            return EMPLOYEE_PAYMENT_DATA_MESSAGES.REQUIRED;
        }

        if (!isValidAccountNumber(account_number)) {
            return EMPLOYEE_PAYMENT_DATA_MESSAGES.ACCOUNT_NUMBER_ERROR;
        }

        return null;
    };

    const validatePayMethod = (values: IInitEmployee): IValidateSalary | object => {
        const { payment_method_id, bank_id, bank_account_type_id, account_number } = values;
        const errors: IValidateSalary = {};

        const paymentMethodsWihtoutBank = [TypesPayMethodWithoutData.CASH, TypesPayMethodWithoutData.INSTRUMENT_NO_DEFINED];

        const shouldValidateNonCashBankOther =
            !paymentMethodsWihtoutBank.find(item => item === payment_method_id) ||
            (paymentMethodsWihtoutBank.find(item => item === payment_method_id) && bank_id && bank_id === BANK_OTHER_ID);

        if (shouldValidateNonCashBankOther) {
            if (!bank_id) {
                errors.bank_id = '* Campo requerido';
            }

            if (!bank_account_type_id) {
                errors.bank_account_type_id = '* Campo requerido';
            }

            const accountNumberError = getAccountNumberErrorMessage(account_number || '');
            if (accountNumberError) {
                errors.account_number = accountNumberError;
            }
        }

        if (account_number && !shouldValidateNonCashBankOther && !isValidAccountNumber(account_number)) {
            errors.account_number = EMPLOYEE_PAYMENT_DATA_MESSAGES.ACCOUNT_NUMBER_ERROR;
        }

        return errors;
    };
    /**
     * Validate salary type
     * @param {IInitEmployee} values - values employee
     * @returns {IValidateSalary | object} error from validation
     */
    const validateSalaryType = (values: IInitEmployee): IValidateSalary | object => {
        const { salary_type_id, contract_type_id, salary } = values;
        const errors: IValidateSalary = {};

        if (contractSENA !== contract_type_id && contractUniversityPractice !== contract_type_id) {
            if (!salary_type_id) {
                errors.salary_type_id = '* Campo requerido';
            }
        }

        if (contractSENA === contract_type_id) {
            if (!salary) {
                errors.salary = '* Campo requerido xf';
            } else if (salary === '0') {
                errors.salary = 'El valor debe ser mayor a 0';
            }
        }

        if (
            [SalaryTypesId.FIXED_SALARY, SalaryTypesId.FULL_SALARY, SalaryTypesId.VARIABLE_SALARY].some(
                item => item === values.salary_type_id
            )
        ) {
            if (!values.salary && values.salary !== '') {
                errors.salary = '* Campo requerido';
            }
        }

        return errors;
    };

    /**
     * validate faling ext
     * @param {IInitEmployee} values - Current employee values
     * @returns {IValidateSalary | object}
     */
    const validateFalingDateExt = (values: IInitEmployee): IValidateSalary | object => {
        const { filing_date_in_ext, colombian_resident_abroad } = values;
        const errors: IValidateSalary = {};

        if (colombian_resident_abroad && !filing_date_in_ext) {
            errors.filing_date_in_ext = '* Campo requerido';
        }
        return errors;
    };

    /**
     * Validate values form with custom validations
     * @param {IInitEmployee} values - Values form
     * @returns {IValidateSalary | object} errors from form
     */
    const validateCustomForm = (values: IInitEmployee): IValidateSalary | object => {
        const documentErrors = validateDocumentNumber(values.document_number, values.document_type_id);
        const salaryErrors = validateIntegralSalary(values);
        const finishDateErrors = validateFinishDate(values.contract_term_id, values.finish_date || null);
        const birthdateErrors = validateBirthdate(values);
        const validateNamesErrors = validateNames(values);
        const validateTaxPayerErrors = validateTaxPayer(values);
        const validateTypeFunderErrors = validateTypeFunders(values);
        const validateEndowmentErrors = validateEndowment(values);
        const validatePayMethodErrors = validatePayMethod(values);
        const validateSalaryTypeErrors = validateSalaryType(values);
        const validatePermanentSalaryErrors = validatePermanentSalary(values);
        const validateFalingDateExtErrors = validateFalingDateExt(values);
        const integralSalaryDateErrors = validateIntegralSalaryDate(values);

        return {
            ...(documentErrors && { ...documentErrors }),
            ...(validateSalaryTypeErrors && { ...validateSalaryTypeErrors }),
            ...(integralSalaryDateErrors && { ...integralSalaryDateErrors }),
            ...(salaryErrors && { ...salaryErrors }),
            ...(finishDateErrors && { ...finishDateErrors }),
            ...(birthdateErrors && { ...birthdateErrors }),
            ...(validateNamesErrors && { ...validateNamesErrors }),
            ...(validateTaxPayerErrors && { ...validateTaxPayerErrors }),
            ...(validateTypeFunderErrors && { ...validateTypeFunderErrors }),
            ...(validateEndowmentErrors && { ...validateEndowmentErrors }),
            ...(validatePayMethodErrors && { ...validatePayMethodErrors }),
            ...(validatePermanentSalaryErrors && { ...validatePermanentSalaryErrors }),
            ...(validateFalingDateExtErrors && { ...validateFalingDateExtErrors }),
        };
    };

    const validateIntegralSalaryDate = (values: IInitEmployee): IValidateSalary | object => {
        const { salary_type_id, start_Integral_Salary } = values;
        const currentType = salary_type?.find(type => type.id === salary_type_id)?.name;
        const errors: IValidateSalary = {};

        if (currentType === INTEGRAL_SALARY && start_Integral_Salary == '') {
            errors.start_Integral_Salary = 'Para el tipo de salario seleccionado se debe de registrar una fecha de inicio';
        }
        return errors;
    };

    return {
        employeeSchema,
        validatePayMethod,
        validateValuesStepOne,
        validateDocumentNumber,
        validateContractTerm,
        validateIntegralSalary,
        validateIntegralSalaryDate,
        validateCustomForm,
        validateSelectionContractTerm,
        validateTypeDocumentNumber,
        validateValuesStepTwo,
        validateValuesStepThree,
    };
};
