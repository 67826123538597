import { combineReducers, Reducer } from 'redux';
import storage from 'redux-persist/es/storage';
import utilsReducer from './utils/utils.slice';
import sessionReducer, { clearSession } from './session/session.slice';
import employeeReducer from './employee/employee.slice';
import companyReducer from './company/company.slice';
import payrollAdjusterReducer from './payroll-adjuster/payrollAdjuster.slice';
import provisionsReducer from './provisions/provisions.slice';
import payslipsReducer from './payslips/payslips.slice';
import reportsReducer from './reports/reports.slice';
import resumeLiquidationReducer from './resume-liquidation/resumeLiquidation.slice';
import pilaReducer from './pila/pila.slice';
import periodsReducer from './periods/periods.slice';
import electronicPayrollReducer from './electronic-payroll/electronicPayroll.slice';
import onboardingReducer from './onboarding/onboarding.slice';
import plansReducer from './plans/plans.slice';
import { payslipPeriodApi } from './rtk-query/payslip/payslipPeriodApi';
import { payslipApi } from './rtk-query/payslip/payslipApi';
import { yearPeriodsApi } from './rtk-query/utils/yearPeriodsApi';
import { employeeContractLiquidationApi } from './rtk-query/employee/contractLiquidationApi';

// Combine reducers
const appReducer = combineReducers({
    utils: utilsReducer || null,
    session: sessionReducer || null,
    employee: employeeReducer || null,
    company: companyReducer || null,
    payrollAdjuster: payrollAdjusterReducer || null,
    payslips: payslipsReducer || null,
    provisions: provisionsReducer || null,
    reports: reportsReducer || null,
    resumeLiquidation: resumeLiquidationReducer || null,
    pila: pilaReducer || null,
    periods: periodsReducer || null,
    electronicPayroll: electronicPayrollReducer || null,
    onboarding: onboardingReducer || null,
    plans: plansReducer || null,
    [payslipPeriodApi.reducerPath]: payslipPeriodApi.reducer,
    [payslipApi.reducerPath]: payslipApi.reducer,
    [yearPeriodsApi.reducerPath]: yearPeriodsApi.reducer,
    [employeeContractLiquidationApi.reducerPath]: employeeContractLiquidationApi.reducer,
});

export const rootReducer: Reducer = (state: RootState, action) => {
    if (clearSession.match(action)) {
        storage.removeItem('persist:root');
        state = {} as RootState;
        return;
    }

    return appReducer(state, action);
};

export type RootState = ReturnType<typeof appReducer>;
