import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
//Hooks
import { useActionsLogin } from '@pages/home/hooks/useActionsLogin';
//Redux
import { RootState } from '@redux/rootReducer';
import { useAppDispatch, useAppSelector } from './redux';
import { setInactive } from '@redux/session/session.slice';
//Utils
import localStorage from '@utils/LocalStorage';

/**
 * Function
 */
export const useIdleTimer = (
    timeout: number
): {
    handleInactive: () => void;
} => {
    const timer = useRef<NodeJS.Timeout>();
    const location = useLocation();
    const navigation = useNavigate();
    const { handleLogoutRedirect } = useActionsLogin();
    const {
        session: { user, inactive },
    } = useAppSelector((state: RootState) => state);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (localStorage.get('INACTIVE') === 'true' && inactive) handleInactive();
    }, []);

    useEffect(() => {
        const resetTimer = (): void => {
            if (timer.current) clearTimeout(timer.current);
            timer.current = setTimeout(() => {
                if (user && user?.homeAccountId) {
                    dispatch(setInactive(true));
                }
            }, timeout);
        };

        const events = ['mousemove', 'mousedown', 'keypress', 'scroll'];
        events.forEach(event => {
            window.addEventListener(event, resetTimer);
        });

        window.addEventListener('popstate', handleBackInactive);
        resetTimer();

        return (): void => {
            events.forEach(event => {
                window.removeEventListener(event, resetTimer);
                window.removeEventListener('popstate', handleBackInactive);
            });
            if (timer.current) clearTimeout(timer.current);
        };
    }, [location, navigation]);

    const handleInactive = (): void => {
        dispatch(setInactive(false));
        if (user && user?.homeAccountId) handleLogoutRedirect();
    };

    const handleBackInactive = (): void => {
        if (localStorage.get('INACTIVE') === 'true') {
            dispatch(setInactive(false));
            if (user && user?.homeAccountId) handleLogoutRedirect();
        }
    };

    return {
        handleInactive,
    };
};
