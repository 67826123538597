// Libraries
import { useEffect, useState } from 'react';
import moment from 'moment';
// Hooks
import { useAppSelector, useAppDispatch } from '@hooks/redux';
import { useEmployee } from './useEmployee';
// Redux
import { RootState } from '@redux/rootReducer';
import { getContributor, putUpdateEmployee } from '@redux/employee/employee.slice';
// Models
import { IAdministrator, IDataUtils } from '@models/Utils';
import { IInitEmployee, IEmployeeData } from '@models/Employee';
import { IOptionSelect } from '@components/input';
// Utils
import { parseValue } from '@utils/Number';
import { formatOptionsCustom } from '@utils/Options';
// Constants
import {
    TERM,
    TypeAdministrator,
    caseOne,
    caseTwo,
    caseThree,
    caseSixteen,
    caseEighteen,
    caseTwentyTwo,
    caseThirty,
    caseThirtyOne,
    caseThirtyTwo,
    caseThirtyFour,
    caseThirtyFive,
    caseFortyFour,
    caseFortySeven,
    caseFiftyOne,
    caseFiftyTwo,
    caseFiftyThree,
    caseFiftyFive,
    caseFiftySeven,
    caseFiftyNine,
    caseSixtyEight,
    caseZero,
    countryColombiaId,
    contractSENA,
    contractUniversityPractice,
    YES_SPANISH,
    SalaryTypesId,
    SEVERANCE_EXCEPTION,
    PENSIONS_EXCEPTION,
} from '@pages/employees/constants/Employees';
import { ActionCreateDispatch, NamesDispatchCreate } from '@constants/ActionsCreateEmployee';

export const useCreateEmployee = (): {
    documentTypes: IOptionSelect[];
    compensationBox: IOptionSelect[];
    typeContributor: IOptionSelect[];
    eps: IOptionSelect[];
    arl: IOptionSelect[];
    pensions: IOptionSelect[];
    layoffs: IOptionSelect[];
    onlyContractTerm: IOptionSelect[];
    contractTerm: IOptionSelect[];
    contractType: IOptionSelect[];
    salaryType: IOptionSelect[];
    type_funder: IDataUtils[];
    handleSubmit: (
        values: IInitEmployee,
        edit: boolean,
        actionModal: () => void,
        nameActions?: string,
        isEditLiquidator?: boolean
    ) => void;
    riskClass: IOptionSelect[];
    validateTypeAndNumberDocument: (values: IInitEmployee) => void;
    subtypeContributor: IOptionSelect[];
    ciuus: IOptionSelect[];
    handleTypeContributorChange: (typeContributorId: string) => void;
} => {
    const dispatch = useAppDispatch();
    const {
        utils: {
            document_type,
            administrator,
            type_contributor,
            compensation_boxes,
            type_funder,
            contract_term,
            contract_type,
            salary_type,
            riskClass: risk_class,
            subtype_contributor,
            ciius_complete,
        },
        company: { id },
        employee: { flagContriburor, employee_stack },
    } = useAppSelector((state: RootState) => state);
    const [documentTypes, setDocumentTypes] = useState<IOptionSelect[]>([]);
    const [typeContributor, setTypeContributor] = useState<IOptionSelect[]>([]);
    const [compensationBox, setCompensationBox] = useState<IOptionSelect[]>([]);
    const [eps, setEps] = useState<IOptionSelect[]>([]);
    const [arl, setArl] = useState<IOptionSelect[]>([]);
    const [riskClass, setRiskClass] = useState<IOptionSelect[]>([]);
    const [subtypeContributor, setSubtypeContributor] = useState<IOptionSelect[]>([]);
    const [ciuus, setCiuus] = useState<IOptionSelect[]>([]);
    const [pensions, setPensions] = useState<IOptionSelect[]>([]);
    const [layoffs, setLayoffs] = useState<IOptionSelect[]>([]);
    const [contractTerm, setContractTerm] = useState<IOptionSelect[]>([]);
    const [onlyContractTerm, setOnlyContractTerm] = useState<IOptionSelect[]>([]);
    const [contractType, setContractType] = useState<IOptionSelect[]>([]);
    const [salaryType, setSalaryType] = useState<IOptionSelect[]>([]);
    const { selectedEmployee } = useEmployee();

    /**
     * Format document type list
     */
    useEffect(() => {
        if (document_type?.length > 0) {
            const newData: IOptionSelect[] = document_type.map(element => {
                return {
                    label: element?.name || '',
                    value: element?.id || '',
                };
            });
            setDocumentTypes(newData);
        }
    }, [document_type]);

    /**
     * Format compensation boxes list
     */
    useEffect(() => {
        if (compensation_boxes?.length > 0) {
            const newData: IOptionSelect[] = formatOptionsCustom(compensation_boxes, 'name');
            setCompensationBox(newData);
        }
    }, [compensation_boxes]);

    /**
     * Format type contributor list
     */
    useEffect(() => {
        if (type_contributor?.length > 0) {
            const newData: IOptionSelect[] = formatOptionsCustom(type_contributor, 'name');
            setTypeContributor(newData);
        }
    }, [type_contributor]);

    /**
     * Format different type from administrator list
     */
    useEffect(() => {
        if (administrator?.length > 0) {
            const listEps: IOptionSelect[] = [];
            const listArl: IOptionSelect[] = [];
            const listPensions: IOptionSelect[] = [];
            const listLayoffs: IOptionSelect[] = [];
            const listRiskClass: IOptionSelect[] = [];
            const listSubtypeContributor: IOptionSelect[] = [];
            const listCiuu: IOptionSelect[] = [];

            administrator.map(element => {
                if (element.type === TypeAdministrator.EPS) {
                    getAdministradorLists(listEps, element);
                }
                if (element.type === TypeAdministrator.ARL) {
                    getAdministradorLists(listArl, element);
                }
                if (element.type === TypeAdministrator.PENSIONS && !PENSIONS_EXCEPTION.some(value => value === element?.id)) {
                    getAdministradorLists(listPensions, element);
                }
                if (element.type === TypeAdministrator.PENSIONS && !SEVERANCE_EXCEPTION.some(value => value === element?.id)) {
                    getAdministradorLists(listLayoffs, element);
                }
                return element;
            });

            risk_class.forEach(element => {
                listRiskClass.push({
                    label: `${element.level} - ${element.class} - ${element.risk_rate}%`,
                    value: element?.id || '',
                });
            });

            subtype_contributor.forEach(element => {
                listSubtypeContributor.push({
                    label: element?.name || '',
                    value: element?.id || '',
                });
            });

            ciius_complete.forEach(element => {
                listCiuu.push({
                    label: `${element.ciiu_complete} - ${element.description}`,
                    value: element?.ciiu_complete || '',
                });
            });

            setEps(listEps);
            setArl(listArl);
            setPensions(listPensions);
            setLayoffs(listLayoffs);
            setRiskClass(listRiskClass);
            setSubtypeContributor(listSubtypeContributor);
            setCiuus(listCiuu);
        }
    }, [administrator]);

    /**
     * Format contract term, contract type and cost center list
     */
    useEffect(() => {
        if (contract_type?.length > 0) {
            const newData: IOptionSelect[] = formatOptionsCustom(contract_type, 'name');
            setContractType(newData);
        }
        if (contract_term?.length > 0) {
            const newData: IOptionSelect[] = formatOptionsCustom(contract_term, 'name');
            const onlyOne = contract_term?.find(element => element.name === TERM);
            if (onlyOne) {
                setOnlyContractTerm([
                    {
                        label: onlyOne.name,
                        value: onlyOne.id,
                    },
                ]);
            }
            setContractTerm(newData);
        }
        if (salary_type?.length > 0) {
            const newData: IOptionSelect[] = formatOptionsCustom(salary_type, 'name');
            setSalaryType(newData);
        }
    }, [contract_term, contract_type, salary_type]);

    /**
     * Action to create or update employee
     * @param {IInitEmployee} values - Values from form
     * @param {boolean} edit - State to edit information
     * @param {() => void} actionModal - Action to close modal
     */
    const handleSubmit = async (
        values: IInitEmployee,
        edit: boolean,
        actionModal: () => void,
        nameAction?: string,
        isEditLiquidator?: boolean
    ): Promise<void> => {
        const formatBirthdate = moment(values.birthdate).format('YYYY-MM-DD');
        const formatStartDate = moment(values.start_date).format('YYYY-MM-DD');
        const formatFinishDate = values.finish_date ? moment(values.finish_date).format('YYYY-MM-DD') : null;
        const validateResetIntegralSalaryDate =
            selectedEmployee?.start_Integral_Salary === null && SalaryTypesId.FULL_SALARY !== values.salary_type_id;
        const formatIntegralSalaryDate =
            values.start_Integral_Salary && !validateResetIntegralSalaryDate
                ? moment(values.start_Integral_Salary).format('YYYY-MM-DD')
                : null;

        const data: IEmployeeData = {
            employee: {
                ...(edit && { id: selectedEmployee?.id }),
                names: values.names || '',
                second_name: values.second_name || '',
                last_names: values.last_names || '',
                second_last_name: values.second_last_name || '',
                document_type_id: values.document_type_id || '',
                document_number: String(values.document_number) || '',
                birthdate: formatBirthdate || '',
                email: values.email || '',
                colombian_resident_abroad: values.colombian_resident_abroad || false,
                foreigner_not_required_to_pay_pension_contributions:
                    values.foreigner_not_required_to_pay_pension_contributions || false,
                filing_date_in_ext: values.filing_date_in_ext || '',
                department_id: values.department_id || '',
                position_id: values.position_id || '',
                cost_center_id: values.cost_center_id || '',
                type_funder_id: values.type_funder_id || '',
                contributor_subtype: values.contributor_subtype || '',
                compensation_box_id: values.compensation_box_id || null,
                cost_center_name: '',
                ciiu_code: values.ciiu_code || '',
                name_branch: values.name_branch || '',
                work_center: values.work_center || '',
                pension_fund_id: values.pension_fund_id || null,
                cesantias_fund_id: values.cesantias_fund_id || null,
                eps_id: values.eps_id || null,
                country_id: values.country_id || countryColombiaId,
                state_id: values.state_id || '',
                city_id: values.city_id || '',
                postal_code: '111111',
                phone: values.phone || '',
                employer_id: id || '',
                risk_class_id: values.risk_class_id || '',
                arl_id: values.arl_id || null,
                exempt_from_parafiscal_taxes: values.exempt_from_parafiscal_taxes || false,
                high_risk_rate_pension: values.high_risk_rate_pension || false,
                address: values.address,
                account_number: values.account_number || '',
                bank_id: values.bank_id || null,
                bank_account_type_id: values.bank_account_type_id || null,
                payment_method_id: values.payment_method_id || '',
                optional_ccf_contribution: values.optional_ccf_contribution || false,
            },
            contract: {
                ...(edit && { id: selectedEmployee?.contract_id }),
                contract_type_id: values.contract_type_id || '',
                contract_term_id: values.contract_term_id || '',
                start_date: formatStartDate || '',
                salary: parseValue(values.salary ?? ''),
                transportation_assistance: values.transportation_assistance || false,
                finish_date: formatFinishDate,
                salary_type_id: values.salary_type_id || SalaryTypesId.FIXED_SALARY,
                permanent_salary_change: edit ? values.permanent_salary_change_id : false,
                has_salary_endowment: values.has_salary_endowment === YES_SPANISH,
                salary_endowment_value: Number((values?.salary_endowment_value as string).replaceAll('.', '')) || 0,
                start_Integral_Salary: formatIntegralSalaryDate,
                has_sustaining_support:
                    values.contract_type_id === contractSENA || values.contract_type_id === contractUniversityPractice,
                ...(edit && { start_date_new_salary: values.start_date_new_salary || null }),
                ...(edit && { finish_date_new_salary: values.finish_date_new_salary || null }),
            },
        };

        if (edit && selectedEmployee && selectedEmployee?.id) {
            dispatch(putUpdateEmployee(selectedEmployee?.id, data, isEditLiquidator, actionModal));
        } else if (
            flagContriburor &&
            document_type.find(({ id }) => id === values.document_type_id)?.code === employee_stack?.TipoDocumento &&
            values.document_number === employee_stack?.NumeroDocumento
        ) {
            const payload = { ...data, update_pila: true };
            dispatch(ActionCreateDispatch[nameAction || NamesDispatchCreate.MAIN].action(payload, actionModal));
        } else {
            dispatch(ActionCreateDispatch[nameAction || NamesDispatchCreate.MAIN].action(data, actionModal));
        }
    };

    const validateTypeAndNumberDocument = (values: IInitEmployee): void => {
        const { document_number, document_type_id } = values;
        const idAbbreviationDocumentType = document_type.find(item => item.id === document_type_id)?.code as string;
        dispatch(
            getContributor({
                documentType: idAbbreviationDocumentType,
                documentNumber: document_number,
            })
        );
    };

    const getSubtypeContributorWithCode = (codes: number[]): void => {
        const arraySubTypeContributors: IOptionSelect[] = [];

        codes.forEach(code => {
            const subTypeContributor = subtype_contributor.find(subtypeContributor => subtypeContributor.code === code);

            arraySubTypeContributors.push({
                label: subTypeContributor?.name || '',
                value: subTypeContributor?.id || '',
            });
        });

        setSubtypeContributor(arraySubTypeContributors);
    };

    const getAdministradorLists = (list: IOptionSelect[], administrator: IAdministrator): void => {
        list.push({
            label: administrator?.name_ael || '',
            value: administrator?.id || '',
        });
    };

    const handleTypeContributorChange = (typeContributorId: string): void => {
        const typeContributorSelected = type_contributor.find(typeContributor => typeContributor.id === typeContributorId);

        switch (typeContributorSelected?.code) {
            case 1:
                getSubtypeContributorWithCode(caseOne);
                break;
            case 2:
                getSubtypeContributorWithCode(caseTwo);
                break;
            case 3:
                getSubtypeContributorWithCode(caseThree);
                break;
            case 12:
                getSubtypeContributorWithCode(caseZero);
                break;
            case 16:
                getSubtypeContributorWithCode(caseSixteen);
                break;
            case 18:
                getSubtypeContributorWithCode(caseEighteen);
                break;
            case 19:
                getSubtypeContributorWithCode(caseZero);
                break;
            case 20:
                getSubtypeContributorWithCode(caseZero);
                break;
            case 21:
                getSubtypeContributorWithCode(caseZero);
                break;
            case 22:
                getSubtypeContributorWithCode(caseTwentyTwo);
                break;
            case 23:
                getSubtypeContributorWithCode(caseZero);
                break;
            case 30:
                getSubtypeContributorWithCode(caseThirty);
                break;
            case 31:
                getSubtypeContributorWithCode(caseThirtyOne);
                break;
            case 32:
                getSubtypeContributorWithCode(caseThirtyTwo);
                break;
            case 34:
                getSubtypeContributorWithCode(caseThirtyFour);
                break;
            case 35:
                getSubtypeContributorWithCode(caseThirtyFive);
                break;
            case 40:
                getSubtypeContributorWithCode(caseZero);
                break;
            case 44:
                getSubtypeContributorWithCode(caseFortyFour);
                break;
            case 45:
                getSubtypeContributorWithCode(caseZero);
                break;
            case 47:
                getSubtypeContributorWithCode(caseFortySeven);
                break;
            case 51:
                getSubtypeContributorWithCode(caseFiftyOne);
                break;
            case 52:
                getSubtypeContributorWithCode(caseFiftyTwo);
                break;
            case 53:
                getSubtypeContributorWithCode(caseFiftyThree);
                break;
            case 54:
                getSubtypeContributorWithCode(caseZero);
                break;
            case 55:
                getSubtypeContributorWithCode(caseFiftyFive);
                break;
            case 57:
                getSubtypeContributorWithCode(caseFiftySeven);
                break;
            case 58:
                getSubtypeContributorWithCode(caseZero);
                break;
            case 59:
                getSubtypeContributorWithCode(caseFiftyNine);
                break;
            case 64:
                getSubtypeContributorWithCode(caseZero);
                break;
            case 65:
                getSubtypeContributorWithCode(caseZero);
                break;
            case 66:
                getSubtypeContributorWithCode(caseZero);
                break;
            case 68:
                getSubtypeContributorWithCode(caseSixtyEight);
                break;
            default:
                setSubtypeContributor(
                    subtype_contributor.map(subtype_contributor => {
                        return {
                            label: subtype_contributor.name,
                            value: subtype_contributor.id,
                        };
                    })
                );
                break;
        }
    };

    return {
        documentTypes,
        compensationBox,
        typeContributor,
        eps,
        arl,
        pensions,
        layoffs,
        onlyContractTerm,
        contractTerm,
        contractType,
        salaryType,
        type_funder,
        handleSubmit,
        riskClass,
        validateTypeAndNumberDocument,
        subtypeContributor,
        ciuus,
        handleTypeContributorChange,
    };
};
