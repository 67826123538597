/**
 * This const describe all type diligence.
 */
export enum TypeDiligence {
    NOT_CREATED = 'Sin Crear Debida Diligencia',
    CREATED = 'Creada Debida Diligencia',
    APPROVE = 'Aprobada Debida Diligencia',
    REJECT = 'Rechazada Debida Diligencia',
    TO_CREATE = 'Para Crear Nueva Debida Diligencia',
    NOTIFIED = 'Notificada Debida Diligencia',
    ERROR_DD = 'Error Debida Diligencia',
}

/**
 * This enum represent the type documents names to accept for client.
 */
export enum TypeDocumentNames {
    TERMS_CONDITIONS = 'Terminos y Condiciones AEL',
    POLITIC_DATA = 'Politica Tratamiento Datos AEL',
}

/**
 * This enum represent all the steps when the client is registering.
 */
export enum OnboardingStepsUser {
    COMPANY_DATA = 0, //
    POLITICAL_DATA = 1,
    DUEDILIGENCE = 2,
    TERMS_CONDITIONS = 3,
    HOME = 5,
    REJECT = 6,
    PLANS = 8,
}

/**
 * Constant to represent the names of the politics that shows in de modal conditions.
 */
export const POLITICS_NAMES: { [key: string]: string } = {
    ['Términos y Condiciones']: process.env.REACT_APP_NAME_DOCUMENT_TyC ?? 'Términos y Condiciones',
    ['Política de Tratamiento de Datos Personales']:
        process.env.REACT_APP_NAME_DOCUMENT_POLICT_DATA ?? 'Política de Tratamiento de Datos Personales',
};

/**
 * Constant to represent the names of the documents.
 */
export const NAME_DOCUMENTS: { [key: string]: string } = {
    [TypeDocumentNames.TERMS_CONDITIONS]: process.env.REACT_APP_NAME_DOCUMENT_TyC ?? 'Terminos y Condiciones AEL',
    [TypeDocumentNames.POLITIC_DATA]: process.env.REACT_APP_NAME_DOCUMENT_POLICT_DATA ?? 'Politica Tratamiento Datos AEL',
};

export const SUPPORT_USER_ROLE = 'support';

export const APPROVE_DOCUMENTS_SUCCESFULL = 'Aprobación Exitosa';

export const REFUSED = 'rechazada';
