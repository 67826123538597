// Libraries
import { useEffect } from 'react';
// Hooks
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { RootState } from '@redux/rootReducer';
// Redux
import {
    getCostCenters,
    getDepartments,
    getPositions,
    setCostCenterSelected,
    setDepartment,
    setPosition,
    getActiveEmployees,
} from '@redux/employee/employee.slice';
import {
    getAllCiius,
    getDynamicData,
    getDynamicEmployee,
    getIntegralMinimumWage,
    getTypesFunder,
} from '@redux/utils/utils.slice';
import { setInPayroll } from '@redux/payroll-adjuster/payrollAdjuster.slice';
// Data
import { dynamicData, dynamicEmployee } from '@pages/employees/constants/Employees';
import { getCarouselPeriods } from '@redux/periods/periods.slice';

export const useInitialData = (): void => {
    const {
        company: {
            id,
            information: { type_funder_id },
        },
        session: { loginUser },
    } = useAppSelector((state: RootState) => state);
    const dispatch = useAppDispatch();

    /**
     * Main actions to load screen
     */
    useEffect(() => {
        if (loginUser?.lastLogin) {
            Promise.all([
                dispatch(getCarouselPeriods()),
                dispatch(getAllCiius()),
                dispatch(getDynamicData(dynamicData)),
                dispatch(getIntegralMinimumWage()),
                dispatch(getDynamicEmployee(dynamicEmployee)),
                dispatch(getDepartments()),
                dispatch(getPositions()),
                dispatch(getCostCenters()),
                dispatch(setDepartment(null)),
                dispatch(setPosition(null)),
                dispatch(setCostCenterSelected(null)),
                id && dispatch(getActiveEmployees()),
            ]);
            dispatch(setInPayroll(false));
        }
    }, []);

    useEffect(() => {
        type_funder_id && dispatch(getTypesFunder());
    }, [type_funder_id]);
};

/**
 * Function to reinitialize detail
 * @returns { {() => void} clearDetail - Action to clear options }
 */
export const useReinitializeDetails = (): { clearDetail: () => void } => {
    const dispatch = useAppDispatch();

    const clearDetail = (): void => {
        Promise.all([dispatch(setDepartment(null)), dispatch(setPosition(null)), dispatch(setCostCenterSelected(null))]);
    };

    return { clearDetail };
};
