// Libraries
import React from 'react';
import moment from 'moment';
// Components
import { CheckboxGreen, DatePickerBase, NumberFormatInput, SelectInput } from '@components/input';
// Models
import { IStepThreeProps } from '@pages/employees/components/models/EmployeeComponents';
// Constants
import {
    YES_SPANISH,
    contractSENA,
    contractUniversityPractice,
    dotationOptions,
    salary,
    supportForSustaining,
} from '@pages/employees/constants/Employees';
import { ToolTipTexts } from '@pages/employees/constants/Tooltips';
// Hooks
import {
    useChangeFinishDate,
    useChangeNewSalaryDate,
    useChangePermanentSalary,
    useChangeSalaryEndowment,
    useChangeStartDate,
    useChangeTransportationAssistance,
    useChangeTypeContract,
    useChangeValues,
    useValidateIntegralSalaryDateRequired,
    useCheckContract,
} from '@pages/employees/hooks/useChangeValues';
import { useStepTwo } from '@pages/employees/hooks/useStepTwo';
import { TextTooltipSalary } from './TextTooltipSalary';
import { ModalImportantInfo } from '@components/modal';
import { TEXT_POPUP_PERMANENT_SALARY_CHANGE } from '@pages/employees/constants/EmployeesTemplate';
import { filterContractsByTypeContributor } from '@utils/Options';
import { ISelectValue } from '@components/input/models/Input';

export const StepThree: React.FC<IStepThreeProps> = ({
    edit,
    disabled,
    onlyContractTerm,
    contractTerm,
    contractType,
    salaryType,
    values,
    validateContractTerm,
    validateSelectionContractTerm,
    showPermanentChangeSalary,
    handleShowPermanentChangeSalary,
    handleNextStepFourth,
}) => {
    const { contract_term_id, start_date, contract_type_id, finish_date, has_salary_endowment } = values;
    useChangeValues(values, contract_type_id);
    const { validateFinishDate } = useChangeFinishDate(values);
    const { validateStratDate } = useChangeStartDate(values);
    const { checkUniversityContract } = useCheckContract(contract_type_id);
    const { integralSalaryDate } = useValidateIntegralSalaryDateRequired(values.salary_type_id || '');
    const { validateAssistance } = useChangeTransportationAssistance();
    useStepTwo();
    const { removeValue, setValue } = useChangeNewSalaryDate();
    const { returnSalary, updateOldSalary } = useChangePermanentSalary();
    useChangeTypeContract(values.type_funder_id, contractType);
    const { validateSalaryEndowment } = useChangeSalaryEndowment();
    return (
        <>
            <ModalImportantInfo
                className="popup-permanent-salary"
                isOpen={showPermanentChangeSalary}
                confirm
                mainAction={(): void => {
                    handleShowPermanentChangeSalary();
                    handleNextStepFourth();
                    updateOldSalary(values.old_salary?.toString() ?? '0');
                }}
                handleBack={(): void => {
                    returnSalary(values.old_salary?.toString() ?? '0');
                    handleShowPermanentChangeSalary();
                }}
            >
                {TEXT_POPUP_PERMANENT_SALARY_CHANGE}
            </ModalImportantInfo>
            <div className="scroll--page">
                <div className="padding--container">
                    <h3 className="title--container">Contrato</h3>
                </div>
                <div className="form__container">
                    <div className="container_mb">
                        <SelectInput
                            name="contract_type_id"
                            label="Tipo de contrato"
                            options={filterContractsByTypeContributor(values.type_funder_id, contractType)}
                            disabled={disabled}
                        />
                    </div>
                    <div className="container_mb">
                        <SelectInput
                            name="contract_term_id"
                            label="Termino de contrato"
                            options={!validateContractTerm(contract_type_id) ? contractTerm : onlyContractTerm}
                            disabled={disabled}
                        />
                    </div>
                    <div className="container_mb">
                        <DatePickerBase
                            name="start_date"
                            label="Fecha de inicio"
                            placeholderText="DD/MM/AAAA"
                            disabled={disabled}
                            maxDate={moment(finish_date).toDate()}
                            customCalendarChange={validateStratDate}
                        />
                    </div>
                    <div className="container_mb">
                        <DatePickerBase
                            name="finish_date"
                            label="Fecha de finalización"
                            placeholderText="DD/MM/AAAA"
                            minDate={moment(start_date).toDate()}
                            disabled={validateSelectionContractTerm(contract_term_id) || disabled}
                            customCalendarChange={validateFinishDate}
                        />
                    </div>
                    {!checkUniversityContract && (
                        <div className="container_mb">
                            <SelectInput
                                name="salary_type_id"
                                label="Tipo de salario"
                                menuPlacement={edit ? 'auto' : 'top'}
                                options={salaryType}
                                disabled={disabled}
                            />
                        </div>
                    )}

                    <div className="container_mb">
                        <NumberFormatInput
                            name="salary"
                            label={
                                contract_type_id === contractSENA || contract_type_id === contractUniversityPractice
                                    ? supportForSustaining
                                    : salary
                            }
                            disabled={disabled}
                            tooltip={true}
                            textTooltip={
                                <TextTooltipSalary contractTypeSena={contract_type_id === contractSENA}></TextTooltipSalary>
                            }
                            onChangeNumberInput={(value): void => validateAssistance(value)}
                        />
                    </div>

                    {integralSalaryDate && (
                        <div className="container_mb">
                            <DatePickerBase
                                name="start_Integral_Salary"
                                label="Fecha de inicio de salario integral"
                                placeholderText="DD/MM/AAAA"
                                disabled={disabled}
                                minDate={moment(start_date).toDate()}
                                maxDate={moment(finish_date).toDate()}
                            />
                        </div>
                    )}

                    {edit && (
                        <div className="container_mb">
                            <CheckboxGreen
                                name="permanent_salary_change_id"
                                label="Cambio permanente de salario"
                                disabled={disabled}
                                tooltip={true}
                                textTooltip={ToolTipTexts.PERMANENT_SALARY_CHANGE}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                    removeValue(event.target.checked);
                                    setValue(event.target.checked);
                                }}
                            />
                        </div>
                    )}
                    {edit && (
                        <div className="container_mb">
                            <DatePickerBase
                                name="start_date_new_salary"
                                label="Fecha de inicio nuevo salario"
                                placeholderText="DD/MM/AAAA"
                                disabled={disabled || !values.permanent_salary_change_id}
                                maxDate={moment(finish_date).toDate()}
                                minDate={moment(start_date).toDate()}
                            />
                        </div>
                    )}
                    <div className="container_mb">
                        <CheckboxGreen
                            name="transportation_assistance"
                            label="Auxilio de transporte/conectividad"
                            disabled={disabled}
                        />
                    </div>
                    {!checkUniversityContract && (
                        <div className="container_mb">
                            <SelectInput
                                menuPlacement="top"
                                name="has_salary_endowment"
                                label="Dotación"
                                options={dotationOptions}
                                disabled={disabled}
                                onChange={(e: ISelectValue | unknown): void => {
                                    validateSalaryEndowment((e as ISelectValue).value);
                                }}
                            />
                        </div>
                    )}
                    {has_salary_endowment === YES_SPANISH &&
                        contract_type_id !== contractSENA &&
                        contract_type_id !== contractUniversityPractice && (
                            <div className="container_mb">
                                <NumberFormatInput name="salary_endowment_value" label="Valor dotación" disabled={disabled} />
                            </div>
                        )}
                </div>
            </div>
        </>
    );
};
