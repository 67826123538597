// Libraries
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
// Redux
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { setDataCompany } from '@redux/session/session.slice';
import { getInformationCompany, setEmployerId, setInformation } from '@redux/company/company.slice';
import { getDynamicData, setLoaderRegisterCompany } from '@redux/utils/utils.slice';
import {
    checkOnboardingStatus,
    saveApprovalTerms,
    saveUserStep,
    setModalErroRequest,
    setUserId,
} from '@redux/onboarding/onboarding.slice';
// Api
import { apiPostSecurityEmployer } from '@api/session';
import { apiGetCompany } from '@api/company';
import { apiGetPlansEmployee } from '@api/plans';
// Hooks
import { useOnboardingActions } from '@hooks/useOnboardingActions';
// Components
import { Routes } from '@components/paths';
// Models
import { RootState } from '@redux/rootReducer';
import { IOptionSelect } from '@components/input';
import { IDataCompany } from '../models/DataCompany';
import { TypesDocumentIds } from '@pages/employees/constants/Employees';
import { DataProcessing, IOnboardingPostInformation } from '@models/Onboarding';
import { ICompanyInformation } from '@models/Company';
import { ICompanyPlan } from '@models/Plans';
// Constants
import { OnboardingStepsUser, TypeDiligence, TypeDocumentNames } from '@constants/Onboarding';
// Utils
import { getTypeDocumentName } from '@utils/TypeDocuments';
import { getRoute } from '@utils/Paths';

export const useDataCompany = (): {
    documentTypes: IOptionSelect[];
    showMessage: boolean;
    handleSubmit: (values: IDataCompany) => void;
    handleShowMessage: () => void;
    showCustomLoader: boolean;
    openDocumentPdf: (typeDocument: TypeDocumentNames | string, is_diligence?: boolean) => void;
    handleSubmitPoliticalData: () => void;
    currentStep: number | null;
    openRedirectModal: boolean;
    handleActionRedirectModal: () => void;
    handleSubmitTermsAndConditions: () => void;
    conditions: DataProcessing[];
    setConditions: Dispatch<SetStateAction<DataProcessing[]>>;
    modalErrorRequest: boolean | null;
    loader: boolean;
    handleClickModalRequest: () => void;
} => {
    const {
        onboarding: { dueDiligence, dataProcessing, step, urlEncrypt, modalErrorRequest, userId },
        utils: { document_type, loaderRegisterCompany, loader },
        company: { id },
        plans: { plansEmployee },
    } = useAppSelector((state: RootState) => state);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { getCurrentDocument, openDocumentPdf } = useOnboardingActions(dataProcessing);
    const [documentTypes, setDocumentTypes] = useState<IOptionSelect[]>([]);
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [openRedirectModal, setOpenRedirectModal] = useState<boolean>(false);
    const [conditions, setConditions] = useState<DataProcessing[]>([]);

    useEffect(() => {
        dispatch(getDynamicData(['document_type']));
    }, []);

    useEffect(() => {
        if (id) {
            checkStatus();
        }
    }, [id]);

    useEffect(() => {
        const currentDocument = getCurrentDocument(
            process.env.REACT_APP_NAME_DOCUMENT_POLICT_DATA ?? TypeDocumentNames.POLITIC_DATA,
            true
        ).document;
        setOpenRedirectModal((TypeDiligence.TO_CREATE === dueDiligence && !currentDocument) || !currentDocument);
    }, [dueDiligence, dataProcessing]);

    useEffect(() => {
        setConditions(dataProcessing);
    }, [dataProcessing]);

    /**
     * Action to check onboarding status.
     */
    const checkStatus = async (): Promise<void> => {
        const MAIN_USER = 0;
        const response = await apiGetCompany<ICompanyInformation>(id);
        const user = response?.users && response?.users[MAIN_USER];
        dispatch(setUserId(user?.user_id));
        dispatch(
            checkOnboardingStatus(
                {
                    document_number: response.document_number || '',
                    type_document: getTypeDocumentName(response.document_type_id || ''),
                    business_name: response.name || '',
                },
                false,
                navigate
            )
        );
    };

    /**
     * Format document types options.
     */
    useEffect(() => {
        if (document_type?.length > 0) {
            const newData: IOptionSelect[] = document_type.map(element => {
                return {
                    label: element?.name || '',
                    value: element?.id || '',
                };
            });
            setDocumentTypes(newData.filter(item => item.value === TypesDocumentIds.NIT || item.value === TypesDocumentIds.CC));
        }
    }, [document_type]);

    /**
     * Action to send data
     * @param {IDataCompany} values - Values form
     */
    const handleSubmit = async (values: IDataCompany): Promise<void> => {
        dispatch(setLoaderRegisterCompany(true));
        const dataFormat = {
            company_name: values.company_name,
            document_number:
                values.document_type_id === TypesDocumentIds.NIT
                    ? `${values.document_number}${values.dv}`
                    : values.document_number,
            document_type_id: values.document_type_id,
        };

        const dataCheckStatus: IOnboardingPostInformation = {
            document_number: dataFormat.document_number,
            type_document: getTypeDocumentName(values.document_type_id),
            business_name: values.company_name,
        };

        const data = await apiPostSecurityEmployer<Pick<ICompanyInformation, 'id'>>(dataFormat);

        if (typeof data === 'string') {
            handleShowMessage();
        } else {
            const company = await apiGetCompany<ICompanyInformation>(data?.id ?? '');
            dispatch(setInformation(company));
            dispatch(setUserId((data as { user_id: string }).user_id));
            dispatch(setEmployerId((data as { id: string })?.id));
            dispatch(setDataCompany(false));
            dispatch(checkOnboardingStatus(dataCheckStatus, true));
        }
    };

    /**
     * Action to submit political data modal.
     */
    const handleSubmitPoliticalData = (): void => {
        const { version, document } = getCurrentDocument(
            process.env.REACT_APP_NAME_DOCUMENT_POLICT_DATA ?? TypeDocumentNames.POLITIC_DATA,
            true
        );
        setOpenRedirectModal(true);
        dispatch(
            saveApprovalTerms(
                {
                    documents: [
                        {
                            date: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                            document,
                            version,
                        },
                    ],
                    user_id: userId,
                    is_dd: true,
                },
                (): void => {
                    window.open(`${process.env.REACT_APP_FORM_DILIGENCE_URL}/${urlEncrypt}`, '_blank', 'noreferrer');
                }
            )
        );
    };

    const handleValidateSubmitCondition = async (): Promise<void> => {
        dispatch(getInformationCompany(id));
        const plans = await apiGetPlansEmployee<ICompanyPlan[]>();
        if (plans?.length || plans?.some(plan => plan.is_active)) {
            dispatch(saveUserStep(OnboardingStepsUser.HOME));
            navigate(getRoute(Routes.HOME));
        } else {
            dispatch(saveUserStep(OnboardingStepsUser.PLANS));
            navigate(getRoute(Routes.PLANS));
        }
    };
    /**
     * Action to submit terms and conditions modal.
     */
    const handleSubmitTermsAndConditions = (): void => {
        dispatch(
            saveApprovalTerms(
                {
                    documents: dataProcessing
                        .filter(item => item.version)
                        .map(item => {
                            return {
                                date: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                                document: item.document,
                                version: item.version,
                            };
                        }),
                    user_id: userId,
                    is_dd: false,
                },
                (): void => {
                    handleValidateSubmitCondition();
                }
            )
        );
    };

    /**
     * Action to open message.
     */
    const handleShowMessage = (): void => {
        setShowMessage(!showMessage);
    };

    /**
     * Action to handle redirect modal.
     */
    const handleActionRedirectModal = (): void => {
        setOpenRedirectModal(!openRedirectModal);
        dispatch(saveUserStep(OnboardingStepsUser.THIRD));
    };

    /**
     * Action to handle click button modal request.
     */
    const handleClickModalRequest = (): void => {
        window.location.reload();
        dispatch(setModalErroRequest(false));
    };

    return {
        documentTypes,
        showMessage,
        handleSubmit,
        handleShowMessage,
        showCustomLoader: loaderRegisterCompany,
        openDocumentPdf,
        handleSubmitPoliticalData,
        currentStep: step,
        handleActionRedirectModal,
        openRedirectModal,
        handleSubmitTermsAndConditions,
        conditions,
        setConditions,
        modalErrorRequest,
        loader,
        handleClickModalRequest,
    };
};
