// Components
import { IOptionSelect } from '@components/input';
// Models
import { IOtherIncome } from '../models/OtherIncome';

export const initialOtherIncome: IOtherIncome = {
    otherSalaryIncome: [
        {
            otherSalaryIncome: '',
            value: '',
        },
    ],
    unearnedIncome: [
        {
            unearnedIncome: '',
            value: '',
        },
    ],
};

export const fakeOtherSalaryIncome: IOptionSelect[] = [
    {
        label: 'Otros ingresos salariales',
        value: 'Otros ingresos salariales',
    },
];

export const fakeUnearnedIncome: IOptionSelect[] = [
    {
        label: 'Ingresos no salariales',
        value: 'Ingresos no salariales',
    },
];

/*
    Validate that the label is only for the first position.
*/
export enum otherIncomeConstants {
    FIRST_ELEMENT_SHOW = 0,
}

export const CONCEPT_TYPE_SENA = 'F1259437-9CD0-44CA-8B97-CA674C74AA3E';

export enum TypesSelect {
    otherSalaryIncome = 'otherSalaryIncome',
    unearnedIncome = 'unearnedIncome',
}

export const TEXT_TOOTIP_OTHER_SALARY_INCOME =
    'Todas las sumas que por su naturaleza sean salario y/o sea convenidas entre las partes como sumas constitutivas de salarios';

export const EXCEPTIONS_INCOMES = ['3CFF7BE2-B152-447A-82A6-D1AA6502A8E1'];
