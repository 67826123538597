import { payslipsPeriodInitialState } from './reports/reports.slice';

export const initialMigration = (state: any): any => {
    return Promise.resolve({
        ...state,
        reports: {
            ...state.reports,
            payslipsPeriod: payslipsPeriodInitialState,
        },
        electronicPayroll: {
            ...state.electronicPayroll,
            isAlreadyTransmitedThisPeriod: false,
        },
    });
};
