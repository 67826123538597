// Constants
import { DATE_FORMAT } from '@constants/Input';
import { STRING_ERROR } from '@constants/OtherTexts';

/**
 * Input messages validation.
 */
export enum ValidationMessage {
    required = '* El campo es requerido',
    email = '* Correo electrónico invalido',
    phone = '* Ingrese un número valido',
    percentage = '* Verifique el porcentaje',
    leadingZeros = '* Ingrese valores mayores que cero',
    name = '* Este campo solo debe contener letras',
    invalidDate = '* La fecha de liquidación no es válida',
    phoneExtension = 'El número debe tener entre 7 a 10 dígitos',
    duplicateEmployee = 'No se puede repetir el empleado',
    integerType = 'El campo debe ser un número entero',
}

/**
 * Input regex validation.
 */
export enum ValidationRegex {
    name = '^[A-zÀ-ÿs\u00F1\u00D1]{1,200}$',
}

/**
 * This function validates that the input type number only will have numbers.
 * @constructor
 * @param  {React.KeyboardEvent<HTMLInputElement>} e - Event object.
 * @returns {void}
 */
export const validateNumbers = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (/[^0-9]/g.test(e.key)) e.preventDefault();
};

/**
 * This function validates that the input type alphanumeric only will have numbers and letters.
 * @constructor
 * @param  {React.KeyboardEvent<HTMLInputElement>} e - Event object.
 * @returns {void}
 */
export const validateAlphanumeric = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (!/^[a-zA-Z0-9ñÑ\s]+$/g.test(e.key)) e.preventDefault();
};

/**
 * This function validates that the input only will have letters
 * @constructor
 * @param  {React.KeyboardEvent<HTMLInputElement>} e - Event object.
 * @returns {void}
 */
export const validateWord = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (!/^[A-Za-zÑñÁáÉéÍíÓóÚúÜü]/g.test(e.key)) e.preventDefault();
};

/**
 * This function validates that the input only contain letters an spaces
 * @constructor
 * @param  {React.KeyboardEvent<HTMLInputElement>} e - Event object.
 * @returns {void}
 */
export const validateWordSpaces = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (/[^A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/g.test(e.key)) e.preventDefault();
};

/**
 * This function validates that the input type text only will have letters.
 * @constructor
 * @param  {string} e - Current input select search.
 * @returns {void}
 */
export const selectSearchOnlyAlphabet = (e: string): string => e.replace(/[^A-Za-z ]/g, '');

/**
 * This function validate thtat the input date picker only receive numbers with the forma dd/mm/yyyy
 * @constructor
 * @param  {React.KeyboardEvent} event - Event object.
 * @returns {void}
 */
export const validateDateInput = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (isNaN(Number(event.key)) && event.key !== '/' && event.key !== 'Backspace') {
        event.preventDefault();
    }

    const inputValue =
        event.key !== 'Backspace'
            ? (event.target as HTMLInputElement).value + event.key
            : (event.target as HTMLInputElement).value;
    const formattedInputValue = inputValue.replace(/[^0-9/]/g, '');

    const parts = formattedInputValue.split('/');
    const day = parts[DATE_FORMAT.DAY] || '';
    const month = parts[DATE_FORMAT.MONTH];
    const year = parts[DATE_FORMAT.YEAR];

    if (!/^(0?[1-9]|[1-2][0-9]|3[0-1])$/.test(day)) {
        event.preventDefault();
    }

    if (month && Number(month) !== 0 && !/^(0?[1-9]|1[0-2])$/.test(month)) {
        event.preventDefault();
    }

    if (year && year.length >= 4 && !/^(\d{4})$/.test(year)) {
        event.preventDefault();
    }
};

/**
 * This function validate if item is equal/different to string error
 * @constructor
 * @param {string} item - Item to validate
 * @param {boolean} isDifferent - Optional flag to validate is equal or different in condition
 * @returns {void}
 */
export const validateStringError = (item: string, isDifferent = false): boolean => {
    if (isDifferent) return item !== STRING_ERROR;

    return item === STRING_ERROR;
};
