// Libraries
import React from 'react';
import { Formik } from 'formik';
// Components
import { Button } from '@components/button';
import { CheckBox } from '@components/input';
import { Modal } from '@components/modal';
// Models
import { IModalTermsConditions } from '../models/ModalConditionsProps';
// Constants
import { NAME_DOCUMENTS, TypeDocumentNames } from '@constants/Onboarding';
// Assets
import logo from '@assets/logos/logo-nav-green.png';
import { removeAccents } from '@utils/Text';

export const ModalTermsConditions: React.FC<IModalTermsConditions> = ({
    handleOpenDocument = (): void => {},
    handleSubmitTermsConditions = (): void => {},
    conditions = [],
    setConditions = (): void => {},
}) => {
    return (
        <Formik initialValues={{ conditions: true }} onSubmit={(): void => {}}>
            <Modal isOpen closeIcon={false} className="modal-term" disabledOverlay>
                <div className="modal-term__container">
                    <img src={logo} className="image-width" />
                    <div className="modal-term__container--text">
                        Señor Aportante tu registro fue aprobado de manera exitosa, para continuar por favor revisa y acepta
                        nuestros Términos y condiciones, y nuestra Política de Tratamiento de Datos Personales del portal de
                        <span> Nómina en Línea.</span>
                    </div>
                    {conditions
                        ?.filter(item => item.document == 'Politica Tratamiento Datos AEL')
                        .map(item => (
                            <div className="modal-term__container--check" key={item.version}>
                                <div>
                                    <CheckBox
                                        name={`conditions`}
                                        onClick={(): void =>
                                            setConditions(
                                                conditions.map(prueba => {
                                                    if (prueba.document === item.document) {
                                                        return {
                                                            ...prueba,
                                                            is_approved: !prueba.is_approved,
                                                        };
                                                    }
                                                    return prueba;
                                                })
                                            )
                                        }
                                    />
                                </div>
                                <div>
                                    He leído y&nbsp;
                                    {NAME_DOCUMENTS[TypeDocumentNames.TERMS_CONDITIONS] == item.document
                                        ? 'acepto los'
                                        : 'autorizo la'}
                                    &nbsp;
                                    <span onClick={(): void => handleOpenDocument(item.document)}>
                                        {removeAccents(NAME_DOCUMENTS[TypeDocumentNames.TERMS_CONDITIONS]) ===
                                        removeAccents(item.document)
                                            ? 'Términos y Condiciones'
                                            : 'Política de Tratamiento de Datos Personales'}
                                    </span>
                                </div>
                            </div>
                        ))}
                    <div className="modal-term__button-container">
                        <Button
                            type="button"
                            onClick={handleSubmitTermsConditions}
                            disabled={
                                conditions
                                    ?.filter(item => item.document == 'Politica Tratamiento Datos AEL')
                                    .some(item => !item.is_approved) || !conditions.length
                            }
                        >
                            Aceptar
                        </Button>
                    </div>
                </div>
            </Modal>
        </Formik>
    );
};
