/**
 * This enum provide periodicity types constants.
 */
export enum PeriodicityTypes {
    MONTHLY = '0B3C7D3B-57A0-49fA-98D5-3CB07A372377',
    FORTNIGHTLY = '0BE9520E-88A9-49F1-B87D-C846B53D5D1C',
}

export const PERIODICITY_NAMES = {
    firstFortnight: 'Primera Quincena',
    secondFortnight: 'Segunda Quincena',
};

/**
 * This enum provide period index constants.
 */
export enum PeriodIndex {
    MONTHLY = 0,
    FORTNIGHTLY = 1,
}
