import { createApi } from '@reduxjs/toolkit/query/react';
import { urls } from '@api/urls';

import { keepDataPerSeconds } from '@redux/constants/queryConfig';
import { baseQuery } from '../baseQuery';
import { ICurrentYearPeriod } from '@models/Utils';

export const yearPeriodsApi = createApi({
    baseQuery,
    reducerPath: 'yearPeriodsApi',
    keepUnusedDataFor: keepDataPerSeconds.yearPeriodApi,
    refetchOnReconnect: true,
    endpoints: build => ({
        getSemesterPeriodsForBonus: build.query<ICurrentYearPeriod[], string>({
            query: (year: string) => {
                return {
                    url: `${urls.utils.yearPeriod.current('bonus', year)}`,
                    method: 'GET',
                };
            },
            transformResponse: (response: ICurrentYearPeriod[]) => response,
        }),
    }),
});

export const { useGetSemesterPeriodsForBonusQuery, util } = yearPeriodsApi;
