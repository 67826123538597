import { RequestDueDiligence } from '@redux/company/types';
import { getEmployeesWithFinishedContractRequest } from '@redux/rtk-query/payslip/types';

export const urls = {
    utils: {
        main: 'utils',
        employee: 'employee/dynamic-request',
        dynamicRequest: 'utils/dynamic-request',
        addressType: (id: number): string => `utils/address-type/${id}`,
        periodicity: {
            getSelect: 'utils/periodicity/all',
            getAll: 'utils/year-period/all',
            getPeriodById: (id: string): string => `utils/year-period/${id}`,
            periodsByYear: 'utils/year-month/year/periodicity',
            getAllPeriodsInYearByPeriodicicity: 'utils/getAllPeriodsInYearByPeriodicicity',
            getByPeriodicity: (id: string): string => `utils/year-month/periodicity/${id}`,
        },
        yearPeriod: {
            current: (type: string, year: string): string => `utils/current-year-period/${type}/${year}`,
            byYear: (year: string): string => `utils/get-periods-info-by-year-and-periodicity/${year}/monthly`,
        },
        typeFunder: (idFunder: string): string => `utils/type-funder/${idFunder}`,
        compensationBox: (departmentId: string): string => `utils/country-state/${departmentId}`,
        conceptType: {
            type: {
                extra_hour: 'utils/concept-type/type/extra_hour',
                surcharge_hour: 'utils/concept-type/type/surcharge_hour',
                incapacity: 'utils/concept-type/type/incapacity',
                license: 'utils/concept-type/type/license',
                vacation: 'utils/concept-type/type/vacation',
                wageIncome: 'utils/concept-type/type/wage_income',
                nonWageIncome: 'utils/concept-type/type/non_wage_income',
                deduction: 'utils/concept-type/type/deduction',
                loan: 'utils/concept-type/type/loan',
                withdrawReason: 'utils/concept-type/type/withdraw_reason',
                liquidationReason: 'utils/concept-type/type/liquidation_reason',
            },
            post: 'utils/concept-type',
            put: (typeId: string): string => `utils/concept-type/${typeId}`,
            delete: (typeId: string): string => `utils/concept-type/${typeId}`,
        },
        ciiusByRisk: 'utils/filter-ciius-complete',
        cityDueDiligence: 'utils/city-due-diligence/all',
        departmentDueDiligence: 'utils/department-due-diligence/all',
    },
    payrollAdjuster: {
        payslipByPeriod: 'employee/payslip-by-period',
        socialBenefits: 'pila/liquidate-social-benefits-pila',
        mainPaymentDateById: (id: string): string => `payslip/payment-date/${id}`,
        mainPaymentDate: 'payslip/payment-date',
    },
    session: {
        lastLogin: (userId: string): string => `employer/users/${userId}`,
        saveUser: 'security/save/user',
        employer: 'security/employer',
    },
    employees: {
        main: 'employee',
        post: 'employee/employees',
        activeEmployees: 'employee/is_active_employees',
        put: (employeeId: string): string => `employee/employees/${employeeId}`,
        get: (employerId: string): string => `employee/employees/${employerId}`,
        detail: (employeeId: string): string => `employee/employee/${employeeId}`,
        department: 'employee/department',
        departments: (employerId: string): string => `employee/departments/${employerId}`,
        specificDepartment: (departmentId: string): string => `employee/department/${departmentId}`,
        position: 'employee/position',
        positions: (employerId: string): string => `employee/positions/${employerId}`,
        specificPosition: (positionId: string): string => `employee/position/${positionId}`,
        costCenter: 'employee/cost',
        costCenters: (employerId: string): string => `employee/costs/${employerId}`,
        specificCost: (costId: string): string => `employee/cost/${costId}`,
        delete: 'employee/employees/delete-employees',
        contributor: {
            getContributor: 'pila/consult-contributor',
            getContributorBase: 'pila/validate-user-reference-base',
        },
        existByEmail: 'employee/exist-employee-by-email',
        existByDocumentNumber: 'employee/exist-employee-by-document-number',
        processContractLiquidation: 'employee/processContractLiquidation',
    },
    company: {
        main: 'employer',
        home: (yearPeriodId: string): string => `employer/home-information/${yearPeriodId}`,
        employers: 'employer/employers',
        periodicity: 'employer/periodicity',
        ciiu: 'employer/ciiu',
        uniqueCompany: (employerId: string): string => `employer/employers/${employerId}`,
        employees: (employerId: string): string => `employee/employees/cont/${employerId}`,
    },
    novelty: {
        absence: 'novelty/absence',
        extra_Hour: 'novelty/extra-hour',
        other_income: 'novelty/income',
        deduction: 'novelty/deduction',
        noveltyByEmployee: 'novelty/novelty/employee/year-period',
        get: (yearPeriodId: string): string => `novelty/novelty/year-period/${yearPeriodId}`,
        delete: (typeNovelty: string, idNovelty: string): string => `novelty/${typeNovelty}/${idNovelty}`,
        rangeDates: (periodId: string): string => `novelty/novelty/days-used-novelties/${periodId}`,
        getUpc: 'novelty/get-upc',
        upc: 'novelty/upc',
        validate_change: 'novelty/novelty-contract/validate-change',
    },
    payslip: {
        servicesBonus: {
            get: (yearPeriodId: string, flag?: boolean): string =>
                !flag
                    ? `payslip/payslips/services-bonus/${yearPeriodId}/bonus`
                    : `payslip/payslips/services-bonus/${yearPeriodId}/bonus/true`,
        },
        severancePay: {
            get: (yearPeriodId: string, flag?: boolean): string =>
                !flag
                    ? `payslip/payslips/services-bonus/${yearPeriodId}/severance`
                    : `payslip/payslips/services-bonus/${yearPeriodId}/severance/true`,
            postWithdrawReason: 'payslip/payslips/severance',
        },
        severanceInterest: {
            get: (yearPeriodId: string, saveInterest = false): string =>
                `payslip/payslips/services-bonus/${yearPeriodId}/severance-interest/${saveInterest || ''}`,
        },
        provisions: {
            main: 'payslip/provision',
        },
        liquidation: {
            savePaymentInformation: 'payslip/payslips/save-payment-information',
            createLiquidation: 'payslip/payslips',
            checkLiquidation: (periodId: string): string =>
                `payslip/payslips/get-payment-info-by-year-period-employer/${periodId}`,
            contractLiquidation: 'payslip/payslips/contract-liquidation',
        },
        receipt: 'payslip/payslips/get-payroll-receipt',
        payrollSlipReport: (periodId: string, employeeId?: string): string =>
            !employeeId
                ? `payslip/get-payroll-slip-report/${periodId}`
                : `payslip/get-payroll-slip-report/${periodId}?employeeId=${employeeId}`,
        historical: (periodId: string): string => `payslip/payslips/get-historical-payroll-report/${periodId}`,
        getEmployeesWithFinishedContract: (request: getEmployeesWithFinishedContractRequest): string =>
            `payslip/getEmployeesWithFinishedContract?yearPeriodId=${request.periodId}&periodicityId=${request.periodicityId}`,
        allEmployeesHavePayslipForPeriod: (yearPeriodId: string): string =>
            `payslip/allEmployeesHavePayslipForPeriod/${yearPeriodId}`,
    },
    reports: {
        pila: {
            getSpreadsheet: 'pila/spreadsheet',
            getCertificate: 'pila/get-pila-contributions-certificate',
        },
        payroll: {
            excelDetails: (periodId: string): string => `payroll/excel-details/${periodId}`,
        },
    },
    pila: {
        summary: 'pila/spreadsheet',
        getTaxpayer: 'pila/consult-taxpayer',
        postPaymentSocialSecurityPila: 'pila/get-pse-url-pay-pila',
        createUser: 'pila/create-user-pila',
        getState: 'pila/get-queue',
    },
    electronicPayroll: {
        saveInformation: 'payroll/save-information',
        generatePayroll: 'payroll/generate-payroll',
        processPayroll: (payrollId: string): string => `payroll/process-payroll/${payrollId}`,
        getPayroll: (payrollId: string): string => `payroll/get-payroll/${payrollId}`,
        checkEmployerStatus: (transmit?: boolean): string => `payroll/check-employer-status${transmit ? '/true' : ''}`,
        getToken: 'pila/get-electronic-payroll-token',
        getYearPeriodTransmit: 'payroll/get-year-period-liquidation-payroll',
    },
    onboarding: {
        status: 'onboarding/user/status',
        approval: 'onboarding/save-approval',
        approvalElectronicPayroll: 'onboarding/save-approval/true',
        saveStep: 'employer/save/step',
        approvalStatus: 'onboarding/approval-status/true',
        dueDiligence: (params: RequestDueDiligence): string =>
            `onboarding/due-diligence?TipoIdentificacion=${params.TipoIdentificacion}&NumeroIdentificacion=${params.NumeroIdentificacion}`,
    },
    payment: {
        plans: 'payment/get-plans',
        statusPurchase: 'payment/consult-transaction',
        createTransaction: 'payment/create-transaction',
        savePayment: 'payment/save-payment',
        getPayments: 'payment/get-payments',
        createLead: 'payment/create-lead',
        redeemCode: 'payment/redeem-code',
        getPromotion: (periodicityId: string): string => `payment/customer-promotion/${periodicityId}`,
    },
};
