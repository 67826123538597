// Libraries
import { Buffer } from 'buffer';
// Hooks
import { usePrintPdf } from './usePrintPdf';
// Models
import { DataProcessing } from '@models/Onboarding';
// Constants
import { NAME_DOCUMENTS, TypeDocumentNames } from '@constants/Onboarding';
import { getNameTypeDocument } from '@utils/OnboardingDocument';

export const useOnboardingActions = (
    data: DataProcessing[]
): {
    openDocumentPdf: (typeDocument: TypeDocumentNames | string, is_diligence?: boolean) => void;
    getCurrentDocument: (typeDocument: TypeDocumentNames | string, is_diligence?: boolean) => DataProcessing;
    getDataProcessingDocumentByName: (typeDocument: TypeDocumentNames | string) => DataProcessing[];
} => {
    const { downloadBase64 } = usePrintPdf();

    /**
     * Action to get current document.
     */
    const getCurrentDocument = (typeDocument: TypeDocumentNames | string, is_diligence?: boolean): DataProcessing => {
        return (
            data.find(
                item => item.document === getNameTypeDocument(typeDocument, NAME_DOCUMENTS) && item.is_dd === is_diligence
            ) || {
                document: '',
                version: '',
                is_approved: false,
                is_dd: false,
                pdf: { data: [], type: 'Buffer' },
            }
        );
    };

    /**
     * Action to open as pdf the document condition.
     */
    const openDocumentPdf = (typeDocument: TypeDocumentNames | string, is_diligence?: boolean): void => {
        const currentDocument = getCurrentDocument(typeDocument, is_diligence || false);
        const convertBase64 = Buffer.from(currentDocument.pdf.data).toString('base64');
        downloadBase64(convertBase64, typeDocument);
    };

    /**
     * Action to get all political treatment documents
     * @param typeDocument name of the document
     * @returns documents
     */
    const getDataProcessingDocumentByName = (typeDocument: TypeDocumentNames | string): DataProcessing[] => {
        const documentsToApprove = data.filter(
            item => item.document === typeDocument || (item.is_dd == true && item.document === typeDocument)
        );
        return documentsToApprove.filter(item => item.document === typeDocument);
    };

    return {
        getCurrentDocument,
        openDocumentPdf,
        getDataProcessingDocumentByName,
    };
};
