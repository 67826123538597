// Libraries
import React, { Suspense, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import Joyride from 'react-joyride';
import moment from 'moment';
import 'moment/locale/es';
// Hooks
import { useAppSelector } from '@hooks/redux';
import { RootState } from '@redux/rootReducer';
import { useMsalInit } from '@hooks/useMsalInit';
import { useTour } from '@hooks/useTour';
import { useActionsLogin } from './pages/home/hooks/useActionsLogin';
import { useIsCustomLoader } from '@hooks/useIsCustomLoader';
import { useFloatingButton } from '@hooks/useFloatingButton';
import { useIdleTimer } from '@hooks/useIdleTimer';
// Components
import { PATHS } from '@components/paths';
import { Navbar } from '@components/navbar';
import { OutsidePlatform } from '@pages/home/components';
import { PrivateRoute } from '@components/private-route';
import { Loader } from '@components/loader';
import { TourTooltip } from '@components/tour-tooltip';
import { FloatingButton } from '@components/floating-button';
import { ModalImportantInfo } from '@components/modal';
// Models
import { path, RouteType } from '@models/Paths';
// Constants
import { Routes as InternalRoutes } from '@components/paths';
import { OnboardingStepsUser } from '@constants/Onboarding';
import { stylesJoyride } from '@constants/TourSteps';
// Utils
import { getRoute } from '@utils/Paths';
import { isEmpty } from '@utils/Object';
// Styles
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/bundle';
import 'tippy.js/dist/tippy.css';
import './App.scss';
// Loader
import loaderGif from '@assets/icons/loader.gif';

const Pages = (props: { accessToken: string }): JSX.Element => {
    useMsalInit();

    return (
        <Routes>
            <Route element={<PrivateRoute redirect="/" isAuthenticated={!!props.accessToken} />}>
                {Object.values(PATHS).map(
                    (path: path, index: number) =>
                        path.type === RouteType.PRIVATE && (
                            <Route path={path.route} key={`path${index}`} element={path.component} />
                        )
                )}
            </Route>
            {Object.values(PATHS).map(
                (path: path, index: number) =>
                    path.type === RouteType.PUBLIC && <Route path={path.route} key={`path${index}`} element={path.component} />
            )}
        </Routes>
    );
};

const App: React.FC = (): React.ReactElement => {
    const {
        session: { accessToken, user, guideTour, loginUser, activeTour, inactive },
        company: { id, information },
        utils: { loader },
        onboarding: { step },
        plans: { activePlan },
    } = useAppSelector((state: RootState) => state);

    const { handleLogoutRedirect } = useActionsLogin();
    const { isCustomLoader } = useIsCustomLoader(step);
    const { tour, handleCallback, handleClickStart } = useTour();
    const { isVisible } = useFloatingButton();
    const currentPath = window.location.pathname;
    const { handleInactive } = useIdleTimer(Number(process.env.REACT_APP_IDLE_TIMEOUT));
    const pathsWithoutPadding = [getRoute(InternalRoutes.PLANS), getRoute(InternalRoutes.REGISTER_REQUEST)];
    const inPlanPage = pathsWithoutPadding.some((item: string) => item === currentPath);
    moment.locale('es');

    useEffect(() => {
        id &&
            !activeTour &&
            !guideTour &&
            !loginUser?.lastLogin &&
            !isEmpty(activePlan || {}) &&
            step === OnboardingStepsUser.HOME &&
            handleClickStart();
    }, [guideTour, id, step, loginUser?.lastLogin]);

    const publicRoutes = [
        ...new Set(Object.values(PATHS).map((path: path) => (path.type === RouteType.PUBLIC ? path.route : ''))),
    ];

    return (
        <div className={id && step === OnboardingStepsUser.HOME && !inPlanPage ? 'padding__container' : ''}>
            <AuthenticatedTemplate>
                {step === OnboardingStepsUser.HOME && (
                    <>
                        {currentPath !== getRoute(InternalRoutes.REGISTER_REQUEST) && !inPlanPage && (
                            <Navbar
                                isAuthenticated={!!user?.homeAccountId || false}
                                activeAccount={user || null}
                                handleLogoutRedirect={handleLogoutRedirect}
                                information={information}
                                guideTour={!!(guideTour || loginUser?.lastLogin)}
                                indexStepTour={tour?.stepIndex}
                            />
                        )}
                        <ModalImportantInfo
                            portalClassName="ReactModal__Overlay__Prioritize"
                            isOpen={inactive}
                            closeIcon={false}
                            confirm={true}
                            textConfirm="Aceptar"
                            mainAction={handleInactive}
                            hiddenBackButton
                        >
                            <div className="flex-row">
                                <img src={loaderGif} alt="loader" />
                                <p>
                                    Hemos detectado un periodo de inactividad, por lo tanto la sesión actual se cerrará. Puedes
                                    iniciar sesión en Nómina en Linea ingresando con tu usuario y contraseña.
                                </p>
                            </div>
                        </ModalImportantInfo>
                        <Joyride
                            callback={handleCallback}
                            continuous
                            run={tour?.run}
                            stepIndex={tour?.stepIndex}
                            steps={tour?.steps}
                            tooltipComponent={TourTooltip}
                            disableOverlayClose
                            disableCloseOnEsc
                            disableScrollParentFix
                            disableScrolling={true} //added to test
                            scrollToFirstStep={true} //added to test
                            spotlightClicks={false}
                            styles={stylesJoyride}
                        />
                        {isVisible && <FloatingButton />}
                    </>
                )}
            </AuthenticatedTemplate>
            {!publicRoutes?.includes(currentPath) && (
                <UnauthenticatedTemplate>
                    <OutsidePlatform />
                </UnauthenticatedTemplate>
            )}
            <Suspense fallback={<div />}>
                {!isCustomLoader && <Loader isOpen={loader} />}
                <Pages accessToken={accessToken} />
            </Suspense>
        </div>
    );
};

export default App;
