// Libraries
import React from 'react';
import { Form, Formik } from 'formik';
// Redux
import { RootState } from '@redux/rootReducer';
import { useAppSelector } from '@hooks/redux';
// Components
import { Input, SelectInput } from '@components/input';
import { Button } from '@components/button';
import { Modal, ModalImportantInfo } from '@components/modal';
import { Loader } from '@components/loader';
import { ModalPoliticalData } from '../modal-political-data/ModalPoliticalData';
import { ModalTermsConditions } from '../modal-terms-conditions/ModalTermsConditions';
import { ModalWaitResponse } from '../modal-wait-response/ModalWaitResponse';
import { ModalRejectResponse } from '../modal-reject-response/ModalRejectResponse';
// Images
import logo from '@assets/logos/logo-nav-green.png';
import points from '@assets/logos/points.svg';
import ael from '@assets/logos/ael.svg';
import loader from '@assets/icons/spinner-white.gif';
// Models
import { IValidateSalary } from '@pages/employees/models/Employee';
// Hooks
import { useDataCompany } from '../hooks/useDataCompany';
import { useValidateDataCompany } from '../hooks/useValidateDataCompany';
// Constants
import { OnboardingStepsUser, TypeDocumentNames } from '@constants/Onboarding';
// Constants
import { TypesDocumentIds } from '@pages/employees/constants/Employees';
// Styles
import './DataCompany.scss';

export const DataCompany: React.FC = () => {
    const {
        documentTypes,
        showMessage,
        handleSubmit,
        handleShowMessage,
        showCustomLoader,
        openDocumentPdf,
        handleSubmitPoliticalData,
        currentStep,
        openRedirectModal,
        handleActionRedirectModal,
        handleSubmitTermsAndConditions,
        conditions,
        setConditions,
        modalErrorRequest,
        loader: loaderStatus,
        handleClickModalRequest,
    } = useDataCompany();
    const { validationSchema, validateNit } = useValidateDataCompany();
    const { loader: loaderState } = useAppSelector((state: RootState) => state.utils);
    const { COMPANY_DATA, POLITICAL_DATA, DUEDILIGENCE, TERMS_CONDITIONS, REJECT } = OnboardingStepsUser;

    return (
        <div className="data-company">
            <ModalImportantInfo
                isOpen={showMessage}
                onAfterOpen={(): void => {}}
                onRequestClose={handleShowMessage}
                className="informative--modal"
                handleBack={handleShowMessage}
            >
                <div className="content__modal">
                    <div className="section__text">
                        <p className="text">Esta empresa ya tiene asociado un usuario, verifique la información registrada.</p>
                    </div>
                    <div className="section__text">
                        <p className="text">Ten presente que para este portal se permite una empresa por cada usuario.</p>
                    </div>
                </div>
            </ModalImportantInfo>
            <Loader isOpen={loaderState} />
            <img src={points} className="img--points" />
            <img src={ael} className="img--ael" />
            <Formik
                initialValues={{
                    company_name: '',
                    document_type_id: '',
                    document_number: '',
                    dv: '',
                }}
                onSubmit={(values, { resetForm }): void => {
                    handleSubmit(values);
                    resetForm();
                }}
                validationSchema={validationSchema}
                validate={(values): object | IValidateSalary => validateNit(values)}
            >
                {({ values }): JSX.Element => {
                    return (
                        <Form>
                            {(currentStep === COMPANY_DATA && !loaderStatus) ||
                                (!currentStep && !loaderStatus && (
                                    <div className="data-form">
                                        <img src={logo} className="img--form" />
                                        <label className="title--form">Datos empresa</label>
                                        <div className="container__inputs">
                                            <Input name="company_name" inputClassContainer="reason--input" label="Razón social" />
                                        </div>
                                        <div className="container__inputs">
                                            <SelectInput
                                                name="document_type_id"
                                                options={documentTypes}
                                                isSearchable
                                                label="Tipo de documento"
                                                classLabel="label-custom"
                                                selectClassContainer="custom--inputs"
                                            />
                                            <Input
                                                name="document_number"
                                                label="Número de documento"
                                                inputClassContainer="custom--inputs"
                                            />
                                            {values.document_type_id === TypesDocumentIds.NIT && (
                                                <Input
                                                    name="dv"
                                                    label="DV"
                                                    inputClassContainer="dv--input"
                                                    maxLength={1}
                                                    type="number"
                                                />
                                            )}
                                        </div>
                                        <div
                                            className={`container__button ${
                                                showCustomLoader && 'container__button--loader-margin'
                                            }`}
                                        >
                                            <Button type="submit">Consultar</Button>
                                        </div>
                                        {showCustomLoader && (
                                            <div className="custom-loader">
                                                <img src={loader} alt="loader" />
                                                Tu consulta se encuentra procesando
                                            </div>
                                        )}
                                    </div>
                                ))}
                            {currentStep === POLITICAL_DATA && !modalErrorRequest && !loaderStatus && (
                                <>
                                    <ModalPoliticalData
                                        handleOpenDocument={(): void => openDocumentPdf(TypeDocumentNames.POLITIC_DATA, false)}
                                        handleSubmitPoliticalData={handleSubmitPoliticalData}
                                    />
                                    <Modal isOpen={openRedirectModal && !modalErrorRequest} closeIcon={false}>
                                        <div className="data-company__modal-redirect">
                                            Se acaba de abrir una nueva pestaña, dirígete a esa pestaña, diligencia el formulario
                                            y regresa a este portal a esperar la validación de la información registrada, hasta
                                            completar todo el proceso de registro.
                                            <div className="data-company__modal-redirect--button-container">
                                                <Button onClick={handleActionRedirectModal}>Aceptar</Button>
                                            </div>
                                        </div>
                                    </Modal>
                                </>
                            )}
                            {currentStep === DUEDILIGENCE && !loaderStatus && <ModalWaitResponse />}
                            {currentStep === REJECT && !loaderStatus && <ModalRejectResponse />}
                            {currentStep === TERMS_CONDITIONS && !modalErrorRequest && !loaderStatus && (
                                <ModalTermsConditions
                                    handleOpenDocument={openDocumentPdf}
                                    handleSubmitTermsConditions={handleSubmitTermsAndConditions}
                                    conditions={conditions}
                                    setConditions={setConditions}
                                />
                            )}
                            <Modal isOpen={modalErrorRequest || false} closeIcon={false}>
                                <div className="data-company__modal-redirect">
                                    En este momento no podemos completar tu proceso de registro. No te preocupes, puedes
                                    intentarlo nuevamente más tarde
                                    <div className="data-company__modal-redirect--button-container">
                                        <Button onClick={handleClickModalRequest}>Recargar</Button>
                                    </div>
                                </div>
                            </Modal>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};
