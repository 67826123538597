import React from 'react';
import { Step } from 'react-joyride';

export const mediaQuery = window.matchMedia('(max-width: 47.938rem)')?.matches;

export const STEPS: Step[] = [
    {
        target: 'body',
        placement: 'center',
        title: '¡Bienvenido a esta nueva experiencia!',
        content: (
            <>
                <p>
                    Te invitamos a que leas detalladamente la información del tour por la plataforma para que tengas claridad
                    sobre lo que debes tener en cuenta en el proceso de liquidación de la nómina de los empleados. Te
                    proporcionaremos los pasos a seguir y tips importantes para que tengas una excelente experiencia con nosotros.
                </p>
            </>
        ),
        disableBeacon: true,
    },
    {
        target: '#title-company',
        spotlightPadding: 0,
        content: (
            <>
                <p>
                    Inicia validando la información de tu empresa y asegúrate de tener los datos completos y actualizados, hasta
                    no completar este paso no puedes continuar con el registro de tus empleados.
                </p>
            </>
        ),
        styles: {
            options: {
                zIndex: 10000,
            },
        },
    },
    {
        target: mediaQuery ? '#menu-responsive' : '#menu',
        spotlightPadding: 0,
        content: (
            <>
                <p>
                    A través de este desplegable podrás acceder nuevamente a la información de&nbsp;
                    <strong className="green">Datos Empresa</strong> y también cerrar tu sesión
                </p>
            </>
        ),
    },
    {
        target: mediaQuery ? '.link-active' : '.body__items--active',
        spotlightPadding: 0,
        content: (
            <>
                <p>
                    En <strong className="green">Mis empleados</strong> puedes realizar el registro de la información de tus
                    empleados, asegurándote de que los datos estén actualizados y completos. Una vez que hayas realizado los
                    registros, podrás visualizar a tus empleados en una lista. Si necesitas editar la información registrada,
                    puedes hacerlo a través del botón editar
                </p>
            </>
        ),
    },
    {
        target: mediaQuery ? '.link-active' : '.body__items--active',
        spotlightPadding: 0,
        content: (
            <>
                <p>
                    En <strong className="green">Liquidación nómina</strong> podrás liquidar la nómina, asegurándote de que la
                    información y el registro de novedades estén completos y actualizados por cada empleado. También podrás
                    obtener el valor de las provisiones, prestaciones sociales y descargar los desprendibles. Recuerda que la
                    correcta validación de la información es crucial para la implementación del proceso de liquidación de la
                    nómina y seguridad social
                </p>
            </>
        ),
    },
    {
        target: '#liquidator',
        spotlightPadding: 0,
        placement: 'top',
        content: (
            <>
                <p>
                    Visualiza el resumen y el detalle de cálculo de la liquidación de tus empleados, recuerda seleccionar el botón
                    Liquidar Nómina para confirmar la liquidación
                </p>
            </>
        ),
    },
    {
        target: '#finished_contracts',
        spotlightPadding: 0,
        placement: mediaQuery ? 'top' : 'right',
        content: (
            <>
                <p>
                    En <strong className="green">Contratos finalizados</strong> encontrarás toda la información de nómina y la
                    liquidación de contrato de los empleados que hayan finalizado contrato durante el periodo de liquidación que
                    estés consultando
                </p>
            </>
        ),
    },
    {
        target: '#liquidator_button',
        spotlightPadding: 0,
        placement: 'top',
        content: (
            <>
                <p>
                    Para obtener la información completa y precisa, es recomendable realizar primero la liquidación del mes
                    anterior utilizando el botón <strong className="green">Liquidar</strong>. Asegúrate de liquidar tanto la
                    nómina del mes en curso como la del mes anterior, ya que esto permitirá a la plataforma calcular correctamente
                    todas las novedades
                </p>
            </>
        ),
    },
    {
        target: '#news_log',
        placement: 'top',
        spotlightPadding: 0,
        content: (
            <>
                <p>
                    Registra las novedades de tus empleados, seleccionando el tipo de novedad y registrando las fechas o el valor
                    correspondiente
                </p>
            </>
        ),
    },
    {
        target: '#payment_slips',
        placement: 'top',
        spotlightPadding: 0,
        content: (
            <>
                <p>Descarga los desprendibles de los empleados una vez confirmes la liquidación con el botón Liquidar nómina</p>
            </>
        ),
    },
    {
        target: '#provisions',
        placement: 'top',
        spotlightPadding: 0,
        content: (
            <>
                <p>Conoce el valor a reservar mes a mes por concepto de prestaciones sociales y vacaciones</p>
            </>
        ),
    },
    {
        target: '#social_benefits',
        placement: 'top',
        spotlightPadding: 0,
        content: (
            <>
                <p>
                    Conoce el valor proyectado al día que ingresas a la plataforma y a la fecha de corte de las prestaciones
                    sociales. Valores actualizados de acuerdo a los días efectivamente trabajados por cada empleado
                </p>
            </>
        ),
    },
    {
        target: mediaQuery ? '.link-active' : '.body__items--active',
        spotlightPadding: 0,
        content: (
            <>
                <p>
                    En <strong className="green">PILA</strong> podrás liquidar la planilla de seguridad social. Verifica la
                    información del resumen de pantalla, asegúrate de que estén ingresadas todas las novedades del periodo
                    correspondiente y finaliza el proceso con el pago de la planilla. Te recomendamos leer instructivo que
                    encontrarás en el botón <strong>Conoce cómo descargar, modificar y pagar la Liquidación PILA</strong>
                </p>
            </>
        ),
    },
    {
        target: mediaQuery ? '.link-active' : '.body__items--active',
        spotlightPadding: 0,
        content: (
            <>
                <p>
                    Si el plan se adquirió con la integración de <strong className="green">Nómina electrónica</strong> una vez
                    liquides PILA debes proceder con la transmisión de la nómina. Realiza el proceso de habilitación ante la DIAN,
                    siguiendo los pasos indicados en el video que encontrarás en este módulo. Luego validá la información del
                    resumen en pantalla y completa tu proceso de transmisión
                </p>
            </>
        ),
    },
    {
        target: mediaQuery ? '.link-active' : '.body__items--active',
        spotlightPadding: 0,
        content: (
            <>
                <p>
                    A través de <strong className="green">Reportes</strong> podrás visualizar y descargar los reportes históricos
                    de la liquidación de nómina, seguridad social, nómina electrónica, desprendibles, prestaciones sociales.
                    Complementa y corrobora la información del área contable de tu empresa, descargando nuestro Excel detallado
                </p>
            </>
        ),
    },
    {
        target: mediaQuery ? '.link-active' : '.body__items--active',
        spotlightPadding: 0,
        content: (
            <>
                <p>
                    En <strong className="green">Inicio</strong> encontrarás un resumen completo del periodo en curso. Incluirá
                    información como los empleados que se incluyeron en la nómina, los nuevos ingresos, los contratos terminados y
                    próximos a vencer. Además, podrás ver los detalles de tu último pago y tendrás acceso directo al liquidador de
                    nómina
                </p>
            </>
        ),
    },
    {
        target: '#pointer',
        spotlightPadding: 0,
        content: (
            <>
                <p>
                    Ten presente que si requieres realizar nuevamente este tour de página lo podrás hacer en la pestaña de &nbsp;
                    <strong className="green">Inicio</strong> haciendo clic en el botón <strong>Conoce nuestro portal</strong>
                </p>
            </>
        ),
    },
    {
        target: '#main-button',
        spotlightPadding: 0,
        styles: {
            spotlight: {
                borderRadius: '50%',
            },
        },
        placement: 'left',
        content: (
            <>
                <p>
                    Este espacio está destinado a que puedas enviar tus dudas y PQR. Estaremos respondiendo a tus solicitudes en
                    la mayor brevedad posible. Además, te ofrecemos la oportunidad de enriquecer tus conocimientos a través de
                    nuestro blog, donde encontrarás información relevante sobre Liquidación de nómina, prestaciones sociales y
                    seguridad social
                </p>
            </>
        ),
    },
    {
        target: '.tippy-box',
        spotlightPadding: 0,
        styles: {
            spotlight: {
                borderRadius: '6.25rem 6.25rem 6.25rem 0',
            },
        },
        content: (
            <>
                <p>
                    En nuestra plataforma encontrarás herramientas de apoyo que te brindarán información sobre ciertas
                    funcionalidades y los datos que visualizas. Ten presente que estas herramientas son de apoyo a tu proceso,
                    pero es tu responsabilidad verificar que la información que proporcionas sea correcta para así evitar
                    inconvenientes en tu liquidación
                </p>
            </>
        ),
    },
    {
        target: 'body',
        spotlightPadding: 0,
        placement: 'center',
        content: (
            <>
                <p>
                    Ten presente que toda información suministrada que edites o elimines en el portal debe ser previamente
                    confirmada, dado que de esta información depende el sistema para realizar el proceso correcto de liquidación
                    de nómina
                </p>
            </>
        ),
    },
    {
        target: 'body',
        spotlightPadding: 0,
        placement: 'center',
        disableBeacon: true,
        content: (
            <>
                <p>
                    Te invitamos a que inicies el proceso registrando los <strong className="green">Datos Empresa</strong> para
                    que puedas hacer uso de todas las funcionalidades de <strong className="green">Nómina en Línea</strong>
                </p>
            </>
        ),
    },
];

export const stylesJoyride = {
    spotlight: {
        borderRadius: '0.9375rem',
        boxShadow: '0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25), 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
    },
};

// This steps follow the guide screen in figma spring SEP'24 SP2
// Each step is the order of screens
export const STEP_DROPDOWN_MENU = 2;
export const STEP_RESPONSIVE_WITHOUT_MENU = [0, 1, 5, 6, 7, 8, 9, 10, 11, 16, 17, 18, 19];
export const STEP_TOOLTIP = 18;
export const responsiveBackRedirect = [5, 15, 16];
export const stepsRedirect = [2, 3, 11, 12, 13, 14, 17];
export const backStepsRedirect = [3, 4, 12, 13, 14, 15, 16, 18];
export const backSteps = [0, 1, 5, 6, 7, 8, 9, 10, 19];
