// Redux
import { AppThunk } from '@redux/configureStore';
import { createSlice } from '@reduxjs/toolkit';
// Models
import { IElectronicPayrollState } from './types';
import {
    IConfirmResponse,
    IElectronicPayrollResponse,
    IEmployerStatus,
    IGeneratePayroll,
    ISaveInformation,
} from '@models/ElectronicPayroll';
// Api
import { getInformationCompany } from '@redux/company/company.slice';
import {
    apiGetCheckEmployerStatus,
    apiGetPayroll,
    apiGetProcessPayroll,
    apiGetElectronicPayrollToken,
    apiGetYearPeriodTransmit,
    apiPostGeneratePayroll,
    apiPostSaveInformation,
} from '@api/electronicPayroll';
// Constants
import { CurrentTabs } from '@pages/electronic-payroll/constants/Tabs';
// Utils
import { checkEmployerCode } from '@pages/electronic-payroll/utils/checkEmployerCode';
import { getApiCarouselPeriods, setCurrentIndex } from '@redux/periods/periods.slice';
import { PeriodicityTypes } from '@constants/Periodicity';
import { CONFIRMED_STATUS, REFUSED } from '@pages/electronic-payroll/constants/Transmit';
import moment from 'moment';

export const initialState: IElectronicPayrollState = {
    error: '',
    payroll: null,
    startTransmit: false,
    confirmed: false,
    hasElectronicPayroll: false,
    currentTab: CurrentTabs.ENABLE_PAYROLL,
    errorModal: false,
    errorGeneratePayrollModal: false,
    responseConfirm: {
        idTrack: null,
        successful: false,
        descripcion: [],
    },
    yearPeriodId: '',
    token: '',
    transmitted: false,
    periods: [],
    isAlreadyTransmitedThisPeriod: false,
};

const electronicPayrollSlice = createSlice({
    name: 'electronicPayroll',
    initialState,
    reducers: {
        setPayroll: (state, action) => {
            state.payroll = action.payload;
        },
        setStartTransmit: (state, action) => {
            state.startTransmit = action.payload;
        },
        setConfirmed: (state, action) => {
            state.confirmed = action.payload;
        },
        setHasElectronicPayroll: (state, action) => {
            state.hasElectronicPayroll = action.payload;
        },
        setCurrentTab: (state, action) => {
            state.currentTab = action.payload;
        },
        setErrorModal: state => {
            state.errorModal = !state.errorModal;
        },
        setErrorGeneratePayrollModal: state => {
            state.errorGeneratePayrollModal = !state.errorGeneratePayrollModal;
        },
        setResponseConfirm: (state, action) => {
            state.responseConfirm = action.payload;
        },
        setYearPeriodId: (state, action) => {
            state.yearPeriodId = action.payload.id;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setTransmitted: (state, action) => {
            state.transmitted = action.payload;
        },
        setPeriods: (state, action) => {
            state.periods = action.payload;
        },
        setIsAlreadyTransmitedThisPeriod: (state, action) => {
            state.isAlreadyTransmitedThisPeriod = action.payload;
        },
        failedRequest: (state, action) => {
            state.error = action.payload;
        },
    },
});

/**
 * Action to save information from electronic payroll
 */
export const postSaveInformation = (data: ISaveInformation): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const { id } = await apiPostSaveInformation(data);
            id && dispatch(getInformationCompany(id));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to generate payroll from electronic payroll
 */
export const postGeneratePayroll = (yearPeriodId: string): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            if (yearPeriodId) {
                const response = await apiPostGeneratePayroll({ year_period_id: yearPeriodId });
                if (response && (response as { errorValidacionPila: boolean })?.errorValidacionPila) {
                    dispatch(setErrorGeneratePayrollModal());
                    dispatch(setPayroll(null));
                    return;
                }
                if (response && (response as { id: string })?.id) {
                    dispatch(setPayroll(response));
                    return;
                }
                dispatch(setPayroll(null));
            }
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get process payroll from electronic payroll
 */
export const getProcessPayroll = (id: string, yearPeriodId: string): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiGetProcessPayroll(id);
            const data = response?.response_service ? JSON.parse(response?.response_service || '') : response;
            if (data) {
                if (data?.successful && data?.idTrack) {
                    dispatch(setIsAlreadyTransmitedThisPeriod(true));
                    dispatch(setConfirmed(true));
                    dispatch(getPayroll(yearPeriodId));
                    return;
                }
                if (!data?.successful) {
                    dispatch(setResponseConfirm(response));
                    dispatch(setIsAlreadyTransmitedThisPeriod(false));
                    dispatch(setErrorModal());
                    return;
                }
                dispatch(setConfirmed(false));
            }
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get process payroll from electronic payroll
 */
export const getPayroll = (yearPeriodId: string): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            if (yearPeriodId) {
                const response = await apiGetPayroll(yearPeriodId);
                if (typeof response !== 'string' && response && (response as { id: string }).id) {
                    dispatch(setPayroll(response));
                    return;
                }
                dispatch(setPayroll(null));
                dispatch(setConfirmed(false));
            }
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Function that get the electronic payroll from a determined period
 * @param yearPeriodId id of the period to be query
 */
export const getTransmitedPayrollByPeriod = (yearPeriodId: string): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const {
            electronicPayroll: { errorModal },
        } = getState();
        try {
            if (yearPeriodId) {
                const response: IGeneratePayroll = await apiGetPayroll(yearPeriodId);
                const transmisionCode = response.status_id.toString();
                const codeValidation = CONFIRMED_STATUS.find(elem => elem == transmisionCode);
                if (typeof response !== 'string' && response && codeValidation) {
                    dispatch(setIsAlreadyTransmitedThisPeriod(true));
                    dispatch(setPayroll(response));
                    const responseConfirmBody: IConfirmResponse = {
                        idTrack: response.id_track,
                        successful: true,
                        descripcion: [],
                    };
                    if (response.status === REFUSED) {
                        const responseFormated: IElectronicPayrollResponse = JSON.parse(response?.response_service || '');
                        responseConfirmBody.successful = true;
                        formatElectronicPayrollErrors(responseFormated, responseConfirmBody);
                        dispatch(setResponseConfirm(responseConfirmBody));
                        if (!errorModal) dispatch(setErrorModal());
                        return;
                    }
                    dispatch(setResponseConfirm(responseConfirmBody));
                    return;
                }
                dispatch(setIsAlreadyTransmitedThisPeriod(false));
                dispatch(setPayroll(null));
            }
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Function that allows to get the error description from electronic payroll response
 * @param electronicPayrollResponse electronic payroll response
 * @param responseConfirmBody structure to fullfill with information */
const formatElectronicPayrollErrors = (
    electronicPayrollResponse: IElectronicPayrollResponse,
    responseConfirmBody: IConfirmResponse
): void => {
    if (electronicPayrollResponse.trabajador.length === 0) {
        responseConfirmBody.descripcion.push(electronicPayrollResponse.descripcion);
        return;
    }
    const trabajadores = electronicPayrollResponse.trabajador;
    trabajadores.forEach(current => {
        const verifyDescriptionIsArray = current.mensajes[0].descripcion.includes('[');
        if (!verifyDescriptionIsArray) {
            responseConfirmBody.descripcion.push(
                `Empleado idenfiticado con ${current.tipoDocumento} ${current.numeroDocumento}: ` +
                    current.mensajes[0].descripcion
            );
        } else {
            const messageInformation = JSON.parse(current.mensajes[0].descripcion);
            const errorArray = JSON.parse(messageInformation[0]);
            if (errorArray.length > 0) {
                errorArray.forEach((currentError: string) => {
                    responseConfirmBody.descripcion.push(
                        `Rechazado empleado idenfiticado con ${current.tipoDocumento} ${current.numeroDocumento}: ` + currentError
                    );
                });
            }
        }
    });
};

/**
 * Action to check employer status
 */
export const getCheckEmployerStatus = (transmit?: boolean): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const {
            utils: { document_type },
            company: { information },
        } = getState();
        try {
            const response = await apiGetCheckEmployerStatus(
                {
                    type_document: String(document_type?.find(type => type.id === information.document_type_id)?.code) || '',
                    document_number: information?.document_number || '',
                },
                transmit
            );
            dispatch(setHasElectronicPayroll((response as IEmployerStatus)?.electronic_payroll || false));
            dispatch(setTransmitted(checkEmployerCode(response as IEmployerStatus)));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get token electronic payroll
 */
export const getTokenElectronicPayroll = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiGetElectronicPayrollToken();
            if (response) dispatch(setToken(response));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get url enable electronic payroll
 */
export const getYearPeriodTransmit = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiGetYearPeriodTransmit();
            if (response && (response as { id?: string })?.id) {
                dispatch(setYearPeriodId(response));
            }
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

export const getPeriodsByYear = (): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const {
            periods: { currentPeriod },
        } = getState();

        try {
            const { periods, period } = await getApiCarouselPeriods(PeriodicityTypes.MONTHLY, currentPeriod);
            if (periods?.length) {
                const electronicPayrollPeriods = [];
                const currentMonth = moment().format('MM');
                const currentYear = moment().year();
                const currentPeriodIndex = periods?.findIndex(period => period.code === `${currentYear}-${currentMonth}`);
                if (currentPeriodIndex != -1) {
                    if (currentPeriodIndex > 0) {
                        electronicPayrollPeriods.push(periods[currentPeriodIndex - 1]);
                    }
                    electronicPayrollPeriods.push(periods[currentPeriodIndex]);
                }
                dispatch(setPeriods(electronicPayrollPeriods));
            }
            dispatch(setCurrentIndex(period?.indexMonth));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

export const {
    setPayroll,
    setStartTransmit,
    setConfirmed,
    setHasElectronicPayroll,
    setCurrentTab,
    setErrorModal,
    setErrorGeneratePayrollModal,
    setResponseConfirm,
    setYearPeriodId,
    setToken,
    setTransmitted,
    setPeriods,
    setIsAlreadyTransmitedThisPeriod,
    failedRequest,
} = electronicPayrollSlice.actions;

export default electronicPayrollSlice.reducer;
