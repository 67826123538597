// Components
import { PATHS, Routes } from '@components/paths';
// Constants
import { OnboardingStepsUser } from './Onboarding';

/**
 * Constant to define all routes to make a custom loader.
 */
export const pathsCustomLoader: string[] = [PATHS[Routes.PILA].route];

/**
 * Constant to define all steps to make a custom loader.
 */
export const stepsCustomLoader: number[] = [OnboardingStepsUser.COMPANY_DATA];
