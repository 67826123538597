// Redux
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '@redux/configureStore';
import { setLoaderRegisterCompany, showLoader } from '@redux/utils/utils.slice';
import { getPayments, getStatusTransaction, setPlansEmployee } from '@redux/plans/plans.slice';
// Types
import { IOnboardingState } from './types';
// Components
import { Routes } from '@components/paths';
// Api
import {
    apiPostOnboardingStatus,
    apiPostSaveStep,
    apiSaveApproval,
    apiSaveApprovalElectronicPayroll,
    apiApproveDataProcessingDocuments,
} from '@api/onboarding';
import { apiGetPlansEmployee } from '@api/plans';
// Constants
import {
    APPROVE_DOCUMENTS_SUCCESFULL,
    NAME_DOCUMENTS,
    OnboardingStepsUser,
    REFUSED,
    TypeDiligence,
    TypeDocumentNames,
} from '@constants/Onboarding';
// Models
import { ApprovalData, ICurrentOnboardingState, IOnboardingPostInformation } from '@models/Onboarding';
import { ICompanyPlan } from '@models/Plans';
// Utils
import { getRoute } from '@utils/Paths';
import { checkIsSupport } from '@utils/CheckSupport';

const initialState: IOnboardingState = {
    dataProcessing: [],
    dueDiligence: '',
    urlEncrypt: '',
    step: null,
    userId: '',
    modalErrorRequest: null,
    error: '',
    isNewCompany: false,
};

const onboardingSlide = createSlice({
    name: 'onboarding',
    initialState,
    reducers: {
        setDataProcessing: (state, action) => {
            state.dataProcessing = action.payload;
        },
        setDueDiligence: (state, action) => {
            state.dueDiligence = action.payload;
        },
        setStep: (state, action) => {
            state.step = action.payload;
        },
        setUserId: (state, action) => {
            state.userId = action.payload;
        },
        setUrlEncrypt: (state, action) => {
            state.urlEncrypt = action.payload;
        },
        setModalErroRequest: (state, action) => {
            state.modalErrorRequest = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },

        setIsNewCompany: (state, action) => {
            state.isNewCompany = action.payload;
        },
    },
});

export const checkOnboardingStatus = (
    information: IOnboardingPostInformation,
    saveEmployer?: boolean,
    navigate?: (route: string) => void
): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const {
            company: { information: companyInformation },
        } = getState();
        !saveEmployer && dispatch(showLoader(true));

        try {
            const response = await apiPostOnboardingStatus<ICurrentOnboardingState>(information);

            const checkPoliticDataDocument = (): boolean | null => {
                return Array.isArray(response?.data_processing)
                    ? response?.data_processing?.some(item => item.document === NAME_DOCUMENTS[TypeDocumentNames.POLITIC_DATA]) ||
                          false
                    : null;
            };
            const userStep =
                companyInformation.users != undefined && companyInformation.users.length > 0
                    ? companyInformation.users[0].step
                    : null;
            dispatch(setDataProcessing(Array.isArray(response.data_processing) ? response.data_processing : []));
            dispatch(setDueDiligence(response.due_diligence));
            dispatch(setUrlEncrypt(response.encrypted));
            dispatch(setIsNewCompany(response.isNewCompany));

            const dueDiligenceErrorValidation =
                response.due_diligence == TypeDiligence.ERROR_DD && userStep != null && userStep === 5;
            if (dueDiligenceErrorValidation) {
                dispatch(saveUserStep(userStep));
                navigate && navigate(getRoute(Routes.HOME));
                return;
            }

            if (!Array.isArray(response.data_processing)) {
                if (userStep != null && response.due_diligence == TypeDiligence.APPROVE && userStep === 5) {
                    dispatch(saveUserStep(userStep));
                    navigate && navigate(getRoute(Routes.HOME));
                    return;
                } else {
                    dispatch(setModalErroRequest(true));
                }
            } else {
                dispatch(setModalErroRequest(false));
                response.data_processing = response.data_processing.filter(d => d.version);
            }

            const validationPoliticalData = response.data_processing.find(
                item => item.document === TypeDocumentNames.POLITIC_DATA
            );
            if (response.data_processing.length) {
                switch (response.due_diligence) {
                    case TypeDiligence.APPROVE:
                        if (validationPoliticalData == undefined) {
                            dispatch(saveUserStep(OnboardingStepsUser.PLANS));
                            navigate && navigate(getRoute(Routes.PLANS));
                        } else {
                            dispatch(saveUserStep(OnboardingStepsUser.POLITICAL_DATA));
                        }
                        break;
                    case TypeDiligence.CREATED:
                        dispatch(saveUserStep(OnboardingStepsUser.DUEDILIGENCE));
                        break;
                    case TypeDiligence.REJECT:
                        dispatch(saveUserStep(OnboardingStepsUser.REJECT));
                        break;
                    case TypeDiligence.NOTIFIED:
                        if (response?.isNewCompany) {
                            const checkDataPolicy = checkPoliticDataDocument();
                            dispatch(
                                saveUserStep(
                                    OnboardingStepsUser.DUEDILIGENCE,
                                    handleDueDiligenceRedirect(checkDataPolicy, response?.encrypted)
                                )
                            );
                        }
                        break;
                    default:
                        if (validationPoliticalData !== undefined) {
                            dispatch(
                                saveUserStep(
                                    OnboardingStepsUser.POLITICAL_DATA,
                                    handleDueDiligenceRedirect(checkPoliticDataDocument(), response?.encrypted)
                                )
                            );
                        } else {
                            dispatch(
                                saveUserStep(
                                    OnboardingStepsUser.DUEDILIGENCE,
                                    handleDueDiligenceRedirect(checkPoliticDataDocument(), response?.encrypted)
                                )
                            );
                        }
                        break;
                }
            }

            if (!response.data_processing.length && response.due_diligence === TypeDiligence.TO_CREATE) {
                const checkOpenTab = false;
                dispatch(
                    saveUserStep(
                        OnboardingStepsUser.POLITICAL_DATA,
                        handleDueDiligenceRedirect(checkOpenTab, response?.encrypted)
                    )
                );
                return;
            }

            if (!response.data_processing.length) {
                checkIsSupport(companyInformation) && (await getStatusTransaction());
                const plans = await apiGetPlansEmployee<ICompanyPlan[]>();
                dispatch(setPlansEmployee(plans));
                dispatch(getPayments());
                const aprobedPlans = plans.filter(item => item.payment_status !== REFUSED);

                if (aprobedPlans?.length > 0 || aprobedPlans?.some(item => item.is_active)) {
                    dispatch(saveUserStep(OnboardingStepsUser.HOME));
                    navigate && navigate(getRoute(Routes.HOME));
                    return;
                }
                dispatch(saveUserStep(OnboardingStepsUser.PLANS));
                navigate && navigate(getRoute(Routes.PLANS));
            }
            dispatch(setLoaderRegisterCompany(false));
            dispatch(showLoader(false));
        } catch (error) {
            dispatch(setLoaderRegisterCompany(false));
            dispatch(showLoader(false));
            dispatch(setError(error));
            dispatch(setModalErroRequest(true));
            dispatch(setIsNewCompany(false));
        }
    };
};

const handleDueDiligenceRedirect = (checkOpenTab: boolean | null, urlEncrypted: string) => {
    return (): void => {
        if (!checkOpenTab && checkOpenTab !== null) {
            window.open(`${process.env.REACT_APP_FORM_DILIGENCE_URL}/${urlEncrypted}`, '_blank', 'noreferrer');
        }
    };
};

export const saveApprovalTerms = (data: ApprovalData, action?: () => void): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            await apiSaveApproval(data);
            action && action();
        } catch (error) {
            dispatch(setError(error));
        }
    };
};

export const saveDataProcessing = (data: ApprovalData, action?: () => void): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const {
            onboarding: { dueDiligence },
        } = getState();
        try {
            const apporveDocumentsResposne = await apiApproveDataProcessingDocuments(data);
            if (apporveDocumentsResposne == APPROVE_DOCUMENTS_SUCCESFULL && dueDiligence == TypeDiligence.APPROVE) {
                dispatch(saveUserStep(OnboardingStepsUser.HOME));
            } else if (apporveDocumentsResposne == APPROVE_DOCUMENTS_SUCCESFULL) {
                dispatch(saveUserStep(OnboardingStepsUser.DUEDILIGENCE));
                action && action();
            } else {
                window.location.reload();
            }
        } catch (error) {
            dispatch(setError(error));
        }
    };
};

export const saveApprovalElectronicPayroll = (data: ApprovalData, action?: () => void): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            await apiSaveApprovalElectronicPayroll(data);
            action && action();
        } catch (error) {
            dispatch(setError(error));
        }
    };
};

export const saveUserStep = (step: number, action?: () => void): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const {
            onboarding: { userId },
        } = getState();
        try {
            await apiPostSaveStep({
                step,
                user_id: userId,
            });
            dispatch(setStep(step));
            action && action();
        } catch (error) {
            dispatch(setError(error));
        }
    };
};

export const handleDataProcessing = (action?: () => void): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            dispatch(setDataProcessing([]));
            action && action();
        } catch (error) {
            dispatch(setError(error));
        }
    };
};

export const {
    setDataProcessing,
    setDueDiligence,
    setStep,
    setUserId,
    setUrlEncrypt,
    setModalErroRequest,
    setIsNewCompany,
    setError,
} = onboardingSlide.actions;

export default onboardingSlide.reducer;
