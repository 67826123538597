// Libraries
// Redux
import { AppThunk } from '@redux/configureStore';
import { createSlice } from '@reduxjs/toolkit';
import { getCheckLiquidation, getCheckLiquidationByFortnight } from '@redux/payslips/payslips.slice';
import { getPeriods } from '@redux/employee/employee.slice';
import {
    getPeriodsPilaByYear,
    postDataPila,
    setCompletedLiquidation,
    setCustomLoader,
    setReliquidate,
    setStateTemplate,
} from '@redux/pila/pila.slice';
// Models
import { IPayrollAdjusterState } from './types';
import {
    FormatGetTable,
    FormatNoveltyByEmployee,
    IAdditionalUpc,
    IConceptType,
    ICreateLiquidation,
    IDataGetUpc,
    IPayloadIncomeAndDeductions,
    IPayloadOvertimeSurcharge,
    IPayloadVacationDisabilityLeaves,
    IPayrollPaymentDate,
    IPreviewEmployee,
    IRequestConcept,
    IUpcEmployee,
} from '@models/PayrollAdjuster';
import { IPeriods } from '@models/Periods';
import { INoveltiesSummary } from '@models/PayrollAdjuster';
import { IGenericOptions } from '@models/Options';
// Constants
import { INITIAL_DATE } from '@pages/payroll-adjuster/constants/InitialDate';
import { PeriodicityTypes } from '@constants/Periodicity';
import { MESSAGE_UNABLE_CREDENTIALS } from '@constants/Api';
import { MESSAGE_ERROR } from '@pages/pila/constants/SectionInformation';
import { ERROR_NOVELTY, GENERAL_ERROR, NOVELTY } from '@constants/OtherTexts';
// Api
import {
    apiDeleteConcept,
    apiDeleteNovelty,
    apiGetAllPeriodicity,
    apiGetDeductionsList,
    apiGetIncapacityList,
    apiGetLicenseList,
    apiGetLoansList,
    apiGetNonWageIncomeList,
    apiGetNoveltyByEmployee,
    apiGetNoveltyYearPeriod,
    apiGetOvertimeList,
    apiGetPayrollPaymentDate,
    apiGetSurchargesList,
    apiGetUpcEmployee,
    apiGetVacationList,
    apiGetWageIncomeList,
    apiPostAdditionalUpc,
    apiPostCreateIncome,
    apiPostCreateLiquidation,
    apiPostDeductions,
    apiPostOtherIncome,
    apiPostOvertimeSurcharges,
    apiPostPayrollAdjusterTable,
    apiPostPayrollPaymentDate,
    apiPostRangeDatesNovelties,
    apiPostSocialBenefits,
    apiPostVacationDisabilityLeaves,
    apiPutConcept,
    apiPutPayrollPaymentDate,
    apiUpdateNovelty,
} from '@api/payrollAdjuster';
import { apiPostGetStateTemplate } from '@api/pila';
// Utils
import { validateStringError } from '@utils/Validations';
import { getMonthlyYearPeriod } from '@utils/Dates';
import { apiGetPeriodsByYear } from '@api/utils';
import { isMonthly } from '@utils/Periodicity';
import { isEmpty } from '@utils/Object';
import { checkErrorByString } from './utils/errorsRequest';
// Components
import { IDeductionsLoans } from '@pages/payroll-adjuster/components/models/Deductions';
import { IOtherIncome } from '@pages/payroll-adjuster/components/models/OtherIncome';
import { initialDeductionsLoans } from '@pages/payroll-adjuster/components/constants/Deductions';
import { initialOtherIncome } from '@pages/payroll-adjuster/components/constants/OtherIncome';
import { mergeArraysUpc } from '@utils/Array';

const initialState: IPayrollAdjusterState = {
    periods: [],
    overtimeList: [],
    paymentDate: '',
    yearMonthlyPeriodId: '',
    hasPaymentDate: false,
    surchargesList: [],
    incapacityList: [],
    licenseList: [],
    vacationList: [],
    wageIncomeList: [],
    nonWageIncomeList: [],
    deductionsList: [],
    loansList: [],
    tableLiquidator: [],
    initialTab: '',
    inPayroll: false,
    noveltiesEmployee: [],
    prevNovelties: { ...initialDeductionsLoans, ...initialOtherIncome },
    previewEmployee: {
        id: '',
        name: '',
        novelties: [],
    },
    currentEmployee: {
        id: '',
        name: '',
        contract_type_name: '',
        salary: 0,
        total_novelties: 0,
        total_payable: 0,
        total_social_security: 0,
        transportation_assistance_value: 0,
        social_security: [],
        document_number: '',
        transportation_assistance: false,
        novelties: [],
        document_type: '',
        accruedSalary: 0,
        workedDays: 0,
        transportation_assistance_concept_type_id: '',
        information: {
            id: '',
            names: '',
            last_names: '',
            document_type_id: '',
            compensation_box_id: '',
            document_number: '',
            birthdate: '',
            email: '',
            department_id: '',
            position_id: '',
            cost_center_id: '',
            rest_days_id: '',
            pension_fund_id: '',
            cesantias_fund_id: '',
            eps_id: '',
            arl_id: '',
            country_id: '',
            state_id: '',
            city_id: '',
            postal_code: '',
            phone: '',
            employer_id: '',
            type_funder_id: '',
            contract: [],
        },
        document_type_code: '',
        transportation_assistance_days: 0,
        cumulative_payable: 0,
        is_valid_transportation_assistance: true,
    },
    currentPeriod: {
        id: '',
        indexMonth: INITIAL_DATE,
        code: '',
        name: '',
        start_date: '',
        end_date: '',
        year_month_id: '',
        periodicity_id: '',
        yearPeriods: [],
        period: {
            id: '',
            code: '',
            name: '',
            start_date: '',
            end_date: '',
            year_month_id: '',
            periodicity_id: '',
        },
        created_at: '',
        updated_at: '',
    },
    novelties: [],
    redirect: {
        employeeId: '',
        mainTab: '',
        optionNovelty: '',
    },
    contractStateTabRedirect: {
        employeeId: '',
        mainTab: '',
        optionNovelty: '',
    },
    novelty: null,
    employeeIdSelected: '',
    income: {
        code: '',
        employer_id: '',
        id: '',
        name: '',
        percentage: '',
        type: '',
    },
    error: '',
    showModalSameDate: {
        showModal: false,
        text: '',
    },
    periodsResumeNovelties: [],
    rangeDatesNovelties: [],
    textModalSuperpositionNovelties: '',
    upcEmployee: [],
    modal_error: {
        message: '',
        open_modal: false,
    },
    idEmployeeFilter: '',
};

const payrollAdjusterSlice = createSlice({
    name: 'payrollAdjuster',
    initialState,
    reducers: {
        setPeriods: (state, action) => {
            state.periods = action.payload;
        },
        setOvertimeList: (state, action) => {
            state.overtimeList = action.payload;
        },
        setSurchargesList: (state, action) => {
            state.surchargesList = action.payload;
        },
        setIncapacityList: (state, action) => {
            state.incapacityList = action.payload;
        },
        setLicensesList: (state, action) => {
            state.licenseList = action.payload;
        },
        setVacationsList: (state, action) => {
            state.vacationList = action.payload;
        },
        setWageIncomeList: (state, action) => {
            state.wageIncomeList = action.payload;
        },
        setNonWageIncomeList: (state, action) => {
            state.nonWageIncomeList = action.payload;
        },
        setDeductionsList: (state, action) => {
            state.deductionsList = action.payload;
        },
        setLoansList: (state, action) => {
            state.loansList = action.payload;
        },
        setCurrentPeriod: (state, action) => {
            state.currentPeriod = action.payload;
        },
        setTableLiquidator: (state, action) => {
            state.tableLiquidator = action.payload;
        },
        setYearMonthlyPeriodId: (state, action) => {
            state.yearMonthlyPeriodId = action.payload;
        },
        setCurrentEmployee: (state, action) => {
            state.currentEmployee = action.payload;
        },
        setPeriod: (state, action) => {
            state.currentPeriod = { ...state.currentPeriod, period: action.payload };
        },
        setPreviewEmployee: (state, action) => {
            state.previewEmployee = action.payload;
        },
        setInPayroll: (state, action) => {
            state.inPayroll = action.payload;
        },
        setNovelties: (state, action) => {
            state.novelties = action.payload;
        },
        setRedirect: (state, action) => {
            state.redirect.employeeId = action.payload?.employeeId || '';
            state.redirect.mainTab = action.payload?.mainTab || '';
            state.redirect.optionNovelty = action.payload?.optionNovelty || '';
        },
        setContractStateTabRedirect: (state, action) => {
            state.contractStateTabRedirect.employeeId = action.payload?.employeeId || '';
            state.contractStateTabRedirect.mainTab = action.payload?.mainTab || '';
            state.contractStateTabRedirect.optionNovelty = action.payload?.optionNovelty || '';
        },
        setNovelty: (state, action) => {
            state.novelty = action.payload;
        },
        setInitialTab: (state, action) => {
            state.initialTab = action.payload;
        },
        setEmployeeIdSelected: (state, action) => {
            state.employeeIdSelected = action.payload;
        },
        setIncome: (state, action) => {
            state.income = action.payload;
        },
        setPrevNovelties: (state, action) => {
            state.prevNovelties = action.payload;
        },
        setShowModalSameDate: (state, action) => {
            state.showModalSameDate.showModal = !state.showModalSameDate.showModal;
            state.showModalSameDate.text = action.payload?.text || '';
        },
        setPeriodsResumeNovelties: (state, action) => {
            state.periodsResumeNovelties = action.payload;
        },
        setRangeDatesNovelties: (state, action) => {
            state.rangeDatesNovelties = action.payload;
        },
        setTextModalSuperpositionNovelties: (state, action) => {
            state.textModalSuperpositionNovelties = action.payload;
        },
        setPaymentDate: (state, action) => {
            state.paymentDate = action.payload;
        },
        setHasPaymentDate: (state, action) => {
            state.hasPaymentDate = action.payload;
        },
        setModalError: (state, action) => {
            state.modal_error = action.payload;
        },
        setUpcEmployee: (state, action) => {
            state.upcEmployee = action.payload;
        },
        resetIncomesOptions: state => {
            state.wageIncomeList = [];
            state.nonWageIncomeList = [];
            state.prevNovelties = [];
        },
        resetDeductionOptions: state => {
            state.deductionsList = [];
            state.loansList = [];
            state.prevNovelties = [];
        },
        setIdEmployeeFilter: (state, action) => {
            state.idEmployeeFilter = action.payload;
        },
        failedRequest: (state, action) => {
            state.error = action.payload;
        },
    },
});

/**
 * Action to get periods by periodicity.
 */
export const getAllPeriods = (): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const {
            company: { information },
            payrollAdjuster: { periods },
        } = getState();

        if (!periods.length) {
            try {
                const response = await apiGetAllPeriodicity<IPeriods[]>(information?.periodicity_id ?? PeriodicityTypes.MONTHLY);
                const periods = response.map(value => ({
                    ...value,
                    period: (value.yearPeriods && value.yearPeriods[INITIAL_DATE]) ?? {},
                    indexMonth: INITIAL_DATE,
                }));
                dispatch(setPeriods(periods));
                dispatch(setCurrentPeriod(periods[INITIAL_DATE]));
            } catch (error) {
                dispatch(failedRequest('Error request data'));
            }
        }
    };
};

/**
 * Action to get overtime list
 */
export const getOvertimeList = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiGetOvertimeList<IConceptType[]>();
            dispatch(setOvertimeList(response || []));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get surcharges list
 */
export const getSurchargesList = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiGetSurchargesList<IConceptType[]>();
            dispatch(setSurchargesList(response || []));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get incapacity list
 */
export const getIncapacityList = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiGetIncapacityList<IConceptType[]>();
            dispatch(setIncapacityList(response || []));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get licenses list
 */
export const getLicensesList = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiGetLicenseList<IConceptType[]>();
            dispatch(setLicensesList(response || []));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get vacations list
 */
export const getVacationsList = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiGetVacationList<IConceptType[]>();
            dispatch(setVacationsList(response || []));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get wage income list
 */
export const getWageIncomeList = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiGetWageIncomeList<IConceptType[]>();
            dispatch(setWageIncomeList(response || []));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get non wage income list
 */
export const getNonWageIncome = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiGetNonWageIncomeList<IConceptType[]>();
            dispatch(setNonWageIncomeList(response || []));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get deductions list
 */
export const getDeductionsList = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiGetDeductionsList<IConceptType[]>();
            dispatch(setDeductionsList(response || []));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get loans list
 */
export const getLoansList = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiGetLoansList<IConceptType[]>();
            dispatch(setLoansList(response || []));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

export const getUpcEmployee = (data: IDataGetUpc): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiGetUpcEmployee<IUpcEmployee>(data);
            if (typeof response !== 'string') {
                dispatch(setUpcEmployee(response));
            } else {
                dispatch(setUpcEmployee(null));
            }
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to create overtime / surcharges
 */
export const postOvertimeSurcharge = (data: IPayloadOvertimeSurcharge[]): AppThunk => {
    return async (dispatch): Promise<void | unknown> => {
        try {
            const response = (await apiPostOvertimeSurcharges(data)) as IGenericOptions;
            if (response?.errors?.length > 0) {
                dispatch(
                    setModalError({
                        open_modal: true,
                        message: response?.errors[0]?.msg || 'Error request data',
                    })
                );
            }
            return response;
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to create liquidation social benefits
 */
export const postLiquidateSocialBenefits = (): AppThunk => {
    return async (dispatch, getState): Promise<void | unknown> => {
        const {
            pila: { reliquidate },
            periods: { monthlyPeriods, currentPeriod },
        } = getState();
        try {
            dispatch(setCompletedLiquidation(false));
            const period = getMonthlyYearPeriod(currentPeriod, monthlyPeriods);
            const response = await apiPostSocialBenefits({ year_period_id: period?.id || currentPeriod?.period?.id });

            const handleGetTemplate = async (id: string, intervalId?: NodeJS.Timer): Promise<void> => {
                const state = await apiPostGetStateTemplate({
                    year_period_id: id,
                });
                dispatch(setStateTemplate(state));

                if (state === MESSAGE_ERROR) {
                    dispatch(postDataPila(id));
                    dispatch(setCustomLoader(false));
                    dispatch(setCompletedLiquidation(true));
                    return intervalId && clearInterval(intervalId);
                }

                if (typeof state !== 'string') {
                    dispatch(postDataPila(id));
                    dispatch(setCompletedLiquidation(true));
                    intervalId && clearInterval(intervalId);
                }
                dispatch(setYearMonthlyPeriodId(''));
            };

            response && dispatch(getPeriodsPilaByYear(handleGetTemplate));
            reliquidate && dispatch(setReliquidate(false));

            if (response === MESSAGE_UNABLE_CREDENTIALS) {
                setModalError({
                    message: response,
                    open_modal: true,
                });
                return response;
            }

            return response;
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

export const postLiquidateSocialBenefitsContractLiquidation = (yearPeriodId: string): AppThunk => {
    return async (dispatch, getState): Promise<void | unknown> => {
        const {
            pila: { reliquidate },
        } = getState();
        try {
            dispatch(setCompletedLiquidation(false));
            const response = await apiPostSocialBenefits({ year_period_id: yearPeriodId });

            const handleGetTemplate = async (id: string, intervalId?: NodeJS.Timer): Promise<void> => {
                const state = await apiPostGetStateTemplate({
                    year_period_id: id,
                });
                dispatch(setStateTemplate(state));

                if (state === MESSAGE_ERROR) {
                    dispatch(postDataPila(id));
                    dispatch(setCustomLoader(false));
                    dispatch(setCompletedLiquidation(true));
                    return intervalId && clearInterval(intervalId);
                }

                if (typeof state !== 'string') {
                    dispatch(postDataPila(id));
                    dispatch(setCompletedLiquidation(true));
                    intervalId && clearInterval(intervalId);
                }
                dispatch(setYearMonthlyPeriodId(''));
            };

            response && dispatch(getPeriodsPilaByYear(handleGetTemplate));
            reliquidate && dispatch(setReliquidate(false));

            if (response === MESSAGE_UNABLE_CREDENTIALS) {
                setModalError({
                    message: response,
                    open_modal: true,
                });
                return response;
            }

            return response;
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to create vacation disability leaves
 */
export const postVacationDisabilityLaves = (data: IPayloadVacationDisabilityLeaves[]): AppThunk => {
    return async (dispatch, getState): Promise<void | unknown> => {
        const {
            periods: { currentPeriod },
            payrollAdjuster: { employeeIdSelected },
        } = getState();
        try {
            const dataResponse = (await apiPostVacationDisabilityLeaves(data)) as IGenericOptions;

            if (typeof dataResponse === 'string') {
                const { isError, message } = checkErrorByString(dataResponse);

                if (isError) {
                    dispatch(
                        setModalError({
                            open_modal: true,
                            message,
                        })
                    );
                    return [
                        {
                            type: ERROR_NOVELTY,
                            message,
                        },
                    ] as INoveltiesSummary[];
                }
            }

            if (dataResponse?.errors?.length > 0) {
                dispatch(
                    setModalError({
                        open_modal: true,
                        message:
                            'Ha ocurrido un error inesperado al registrar la novedad, por favor intente más tarde o contacte a soporte.',
                    })
                );

                return [
                    {
                        type: ERROR_NOVELTY,
                        message: dataResponse?.errors[0]?.msg || 'Error request data',
                    },
                ] as INoveltiesSummary[];
            }
            const response = dataResponse as INoveltiesSummary[];
            currentPeriod?.period?.id && dispatch(getNoveltyYearPeriod(currentPeriod?.period?.id));
            if (response?.[NOVELTY]?.type === ERROR_NOVELTY) {
                dispatch(setTextModalSuperpositionNovelties(response[NOVELTY].message));
                return response[NOVELTY].message;
            }

            if (response?.some(({ type }) => validateStringError(type))) {
                dispatch(
                    setTextModalSuperpositionNovelties(response?.find(({ type }) => validateStringError(type))?.message || '')
                );
            }
            dispatch(getRangeDatesNovelties(employeeIdSelected));

            return response;
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to create other income
 */
export const postOtherIncome = (data: IPayloadIncomeAndDeductions[]): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = (await apiPostOtherIncome(data)) as IGenericOptions;
            if (response?.errors?.length > 0) {
                dispatch(
                    setModalError({
                        open_modal: true,
                        message: response?.errors[0]?.msg || 'Error request data',
                    })
                );
            }
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action additional upc
 */
export const postAdditionalUpc = (data: IAdditionalUpc): AppThunk => {
    return async (dispatch, getState): Promise<void | string> => {
        try {
            const {
                payrollAdjuster: { upcEmployee },
            } = getState();
            const response = (await apiPostAdditionalUpc(data)) as IGenericOptions;
            if (response?.errors?.length > 0 || response?.error?.length > 0) {
                dispatch(
                    setModalError({
                        open_modal: true,
                        message: GENERAL_ERROR,
                    })
                );
            }
            if ((response as { novelty: IUpcEmployee[] })?.novelty) {
                const newUpc = mergeArraysUpc(upcEmployee ?? [], (response as { novelty: IUpcEmployee[] })?.novelty);
                dispatch(setUpcEmployee(newUpc));
            }
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to create deductions
 */
export const postDeductions = (data: IPayloadIncomeAndDeductions[]): AppThunk => {
    return async (dispatch): Promise<void | unknown> => {
        try {
            const response = (await apiPostDeductions(data)) as IGenericOptions;
            if (response?.errors?.length > 0) {
                dispatch(
                    setModalError({
                        open_modal: true,
                        message: response?.errors[0]?.msg || 'Error request data',
                    })
                );
            }
            return response;
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get data table.
 */
export const getTableEmployeeLiquidator = (data: FormatGetTable): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        try {
            const {
                periods: { currentIndex, periods },
            } = getState();
            const actualYear = periods[currentIndex ?? new Date().getMonth()];
            const monthlyPeriods = await apiGetPeriodsByYear<IPeriods[]>(actualYear.code.substring(0, 4));
            const monthSelected = Number(actualYear.code.substring(5, 7)) - 1;
            const currentMonth = monthlyPeriods[monthSelected ?? new Date().getMonth()];
            const response = await apiPostPayrollAdjusterTable({ ...data, year_monthly_period_id: currentMonth.id });
            if (!Array.isArray(response)) {
                dispatch(setTableLiquidator([]));
                dispatch(setYearMonthlyPeriodId(currentMonth.id));
                return;
            }
            dispatch(setTableLiquidator(response));
            dispatch(setYearMonthlyPeriodId(currentMonth.id));
        } catch (error) {
            dispatch(setTableLiquidator([]));
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get data table.
 */
export const getNoveltiesByEmployee = (data: FormatNoveltyByEmployee): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiGetNoveltyByEmployee<IPreviewEmployee[]>(data);
            response.length > 0 && dispatch(setPreviewEmployee(response[0]));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action get novelties by employee
 */
export const getNoveltyYearPeriod = (id: string): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiGetNoveltyYearPeriod(id);
            dispatch(setNovelties(response));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action update novelty
 */
export const updateNovelty = (
    data: IPayloadOvertimeSurcharge | IPayloadVacationDisabilityLeaves | IPayloadIncomeAndDeductions | { value: number }
): AppThunk => {
    return async (dispatch, getState): Promise<void | unknown> => {
        const {
            payrollAdjuster: { novelty },
            periods: { currentPeriod },
        } = getState();
        try {
            const dataResponse = (await apiUpdateNovelty(
                data,
                novelty?.type.replace('_', '-') || '',
                novelty?.id || ''
            )) as IGenericOptions;
            if (dataResponse?.errors?.length > 0) {
                dispatch(
                    setModalError({
                        open_modal: true,
                        message: dataResponse?.errors[0].msg || 'Error request data',
                    })
                );
                return [
                    {
                        type: ERROR_NOVELTY,
                        message: dataResponse?.errors[0]?.msg || 'Error request data',
                    },
                ] as INoveltiesSummary[];
            }
            currentPeriod?.period?.id && dispatch(getNoveltyYearPeriod(currentPeriod?.period?.id));
            dispatch(setNovelty(null));
            dispatch(
                setRedirect({
                    employeeId: '',
                    mainTab: '',
                    optionNovelty: '',
                })
            );
            return dataResponse;
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action delete novelty
 */
export const deleteNovelty = (): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const {
            payrollAdjuster: { novelty },
            periods: { currentPeriod },
        } = getState();
        try {
            const response = (await apiDeleteNovelty(
                novelty?.type.replace('_', '-') || '',
                novelty?.id || ''
            )) as IGenericOptions;
            if (response?.errors?.length > 0) {
                dispatch(
                    setModalError({
                        open_modal: true,
                        message: response?.errors[0]?.msg || 'Error request data',
                    })
                );
            } else {
                if (novelty?.employee_id) dispatch(getRangeDatesNovelties(novelty.employee_id));
                dispatch(setNovelty(null));
                currentPeriod?.period?.id && dispatch(getNoveltyYearPeriod(currentPeriod?.period?.id));
            }
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

export const deleteNoveltyByEmployee = (data: FormatNoveltyByEmployee): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const {
            payrollAdjuster: { novelty },
        } = getState();
        try {
            if (novelty && novelty.type && novelty.id) {
                await apiDeleteNovelty(novelty?.type.replace('_', '-'), novelty?.id);
                dispatch(setNovelty(null));
                dispatch(getNoveltiesByEmployee(data));
            }
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

export const addNewConceptIncome = (data: IRequestConcept): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            if (data?.employer_id) {
                const response = await apiPostCreateIncome(data);
                dispatch(getWageIncomeList());
                dispatch(getNonWageIncome());
                dispatch(getDeductionsList());
                dispatch(getLoansList());
                dispatch(setIncome(response));
            }
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

export const updateConceptType = (data: IRequestConcept): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            if (data?.employer_id) {
                const response = await apiPutConcept(data, data?.id ?? '');
                dispatch(getWageIncomeList());
                dispatch(getNonWageIncome());
                dispatch(getDeductionsList());
                dispatch(getLoansList());
                dispatch(setIncome(response));
            }
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

export const deleteConceptType = (data: IRequestConcept, novelties: IDeductionsLoans | IOtherIncome | []): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            if (data?.employer_id && data?.id) {
                const res = (await apiDeleteConcept(data, data?.id ?? '')) as { message: string; error: string };
                if (res?.error) {
                    dispatch(
                        setModalError({
                            message: res?.error,
                            open_modal: true,
                        })
                    );
                    return;
                }

                const key: { [key: string]: string } = {
                    deduction: 'deductions',
                    loan: 'loans',
                    wage_income: 'otherSalaryIncome',
                    non_wage_income: 'unearnedIncome',
                };

                const obj = { ...initialDeductionsLoans, ...initialOtherIncome };

                const filterNovelties = (novelties as unknown as { [key: string]: [] })?.[key[data?.type]]?.filter(
                    (item: { [key: string]: string }) => item[key[data?.type]] !== data?.id
                );

                (novelties as unknown as { [key: string]: IDeductionsLoans[] })[key[data?.type]] =
                    filterNovelties.length === 0 ? (obj as never)[key[data?.type]] : filterNovelties;

                dispatch(setPrevNovelties(novelties));
                dispatch(getWageIncomeList());
                dispatch(getNonWageIncome());
                dispatch(getDeductionsList());
                dispatch(getLoansList());
                dispatch(setIncome(null));
            }
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get dates range from disabilities novelties.
 */
export const getRangeDatesNovelties = (employeeId: string): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const {
            periods: { currentPeriod },
        } = getState();
        try {
            const response = await apiPostRangeDatesNovelties(currentPeriod.period.id, { employee_id: employeeId });
            dispatch(setRangeDatesNovelties(response));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to create liquidation employees by period.
 */
export const postCreateLiquidation = (data: ICreateLiquidation, action?: () => void): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const {
            company: { information },
        } = getState();
        try {
            await changePaylistPaymentDate();
            const response = await apiPostCreateLiquidation({ ...data });
            if (Array.isArray(response) && response?.length) {
                !isMonthly(information.periodicity_id) && dispatch(getCheckLiquidationByFortnight());
                dispatch(getCheckLiquidation());
            }
            action && action();
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to create liquidation employees by period.
 */
export const changePaylistPaymentDate = (): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const {
            periods: { currentPeriod },
            payrollAdjuster: { paymentDate, hasPaymentDate },
        } = getState();
        try {
            hasPaymentDate &&
                (await apiPutPayrollPaymentDate(currentPeriod?.period?.id, {
                    payment_date: paymentDate,
                    year_period_id: currentPeriod?.period?.id,
                }));
            !hasPaymentDate &&
                (await apiPostPayrollPaymentDate({
                    payment_date: paymentDate,
                    year_period_id: currentPeriod?.period?.id,
                }));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get initial data for payroll.
 */
export const getPayrollPaymentDate = (periodId: string): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiGetPayrollPaymentDate<IPayrollPaymentDate>(periodId);
            if (isEmpty(response) || typeof response === 'string') {
                dispatch(setPaymentDate(''));
                dispatch(setHasPaymentDate(false));
                return;
            }
            dispatch(setPaymentDate(response.payment_date));
            dispatch(setHasPaymentDate(true));
        } catch {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get initial data for payroll.
 */
export const getInitialPayrollData = (periodicity?: string): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const {
            company: { id },
        } = getState();

        try {
            const period = (await dispatch(getPeriods(periodicity))) as IPeriods;
            dispatch(getCheckLiquidation());
            dispatch(
                getTableEmployeeLiquidator({
                    employer_id: id,
                    start_date: period?.period?.start_date,
                    year_period_id: period?.period?.id,
                })
            );
            dispatch(getPayrollPaymentDate(period?.period?.id || ''));
        } catch {
            dispatch(failedRequest('Error request data'));
        }
    };
};

export const {
    setPeriods,
    setOvertimeList,
    setSurchargesList,
    setIncapacityList,
    setLicensesList,
    setVacationsList,
    setWageIncomeList,
    setNonWageIncomeList,
    setLoansList,
    setDeductionsList,
    setCurrentPeriod,
    setPeriod,
    failedRequest,
    setTableLiquidator,
    setYearMonthlyPeriodId,
    setCurrentEmployee,
    setPreviewEmployee,
    setInPayroll,
    setNovelties,
    setRedirect,
    setContractStateTabRedirect,
    setNovelty,
    setInitialTab,
    setEmployeeIdSelected,
    setIncome,
    setPrevNovelties,
    setShowModalSameDate,
    setPeriodsResumeNovelties,
    setRangeDatesNovelties,
    setTextModalSuperpositionNovelties,
    setPaymentDate,
    setHasPaymentDate,
    setUpcEmployee,
    setModalError,
    resetIncomesOptions,
    resetDeductionOptions,
    setIdEmployeeFilter,
} = payrollAdjusterSlice.actions;

export default payrollAdjusterSlice.reducer;
