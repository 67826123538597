import { createApi } from '@reduxjs/toolkit/query/react';
import { urls } from '@api/urls';

import { keepDataPerSeconds } from '@redux/constants/queryConfig';
import { baseQuery } from '../baseQuery';
import { TProcessContractLiquidationRequest, TProcessContractLiquidationResponse } from './types';

export const employeeContractLiquidationApi = createApi({
    baseQuery,
    reducerPath: 'employeeContractLiquidationApi',
    keepUnusedDataFor: keepDataPerSeconds.employeeContractLiquidationApi,
    refetchOnReconnect: true,
    endpoints: build => ({
        processContractLiquidation: build.mutation<TProcessContractLiquidationResponse, TProcessContractLiquidationRequest>({
            query: request => ({
                url: urls.employees.processContractLiquidation,
                method: 'POST',
                body: request,
            }),
        }),
    }),
});

export const { useProcessContractLiquidationMutation, util } = employeeContractLiquidationApi;
