// Api
import AxiosClient from '@api/axiosClient';
// Urls
import { urls } from './urls';
// Models
import { IRequestSocialBenefits, IPayloadWithdrawReason, IPaymentSlipRequest } from '@models/payslips';

// Bonus Service
export const apiGetBonusService = <T>(yearPeriodId: string, flag: boolean, request?: IRequestSocialBenefits): Promise<T> =>
    AxiosClient.post(urls.payslip.servicesBonus.get(yearPeriodId, flag), request);

// Severance Pay
export const apiGetSeverancePay = <T>(yearPeriodId: string, flag: boolean, request?: IRequestSocialBenefits): Promise<T> =>
    AxiosClient.post(urls.payslip.severancePay.get(yearPeriodId, flag), request);

// Interest severance pay
export const apiGetInterestSeverancePay = <T>(yearPeriodId: string, saveInterest = false): Promise<T> =>
    AxiosClient.post(urls.payslip.severanceInterest.get(yearPeriodId, saveInterest), {});

// Get data to generate pdf
export const apiGetPaymentSlipData = (employerId: string, data: IPaymentSlipRequest): Promise<unknown> =>
    AxiosClient.post(urls.payslip.receipt, data, false);

// Check if an employer has liquidation
export const apiCheckLiquidation = <T>(periodId: string): Promise<T> =>
    AxiosClient.get(urls.payslip.liquidation.checkLiquidation(periodId), {});

//  Send withdraw reason
export const apiPostWithdrawReason = (data: IPayloadWithdrawReason): Promise<unknown> =>
    AxiosClient.post(urls.payslip.severancePay.postWithdrawReason, data, false);

// Get historical payroll report
export const apiGetHistoricalReport = <T>(periodId: string): Promise<T> => AxiosClient.get(urls.payslip.historical(periodId), {});

export const apiGetPayrollSlipReport = <T>(data: IPaymentSlipRequest): Promise<T> => {
    const { year_period, employee_id } = data;
    const url = employee_id
        ? urls.payslip.payrollSlipReport(year_period, employee_id)
        : urls.payslip.payrollSlipReport(year_period);

    return AxiosClient.get(url, {});
};
