// Libraries
import { useEffect } from 'react';
import { useFormikContext } from 'formik';
// Models
import { IInitEmployee } from '@models/Employee';
// Hooks
import { useValidate } from './useValidate';
import { useCreateDetails } from './useCreateDetails';
// Constants
import {
    CONTRACT_TERM_ID,
    COST_CENTER_ID,
    DEPARTMENT_ID,
    FINISH_DATE,
    LAST_NAME,
    NAME,
    NEW_SALARY_DATE,
    POSITION_ID,
    SECOND_LAST_NAME,
    SECOND_NAME,
    STRING,
    StepTwoValues,
    TAXPAYER_DEPENDENT,
    TRANSPORTATION_ASSISTANCE,
    contractSENA,
    contractUniversityPractice,
    PERMANENT_SALARY_CHANGE,
    YES_SPANISH,
    SalaryTypesId,
} from '@pages/employees/constants/Employees';
// Utils
import { parseValue } from '@utils/Number';
import { capitalizeFirstLetter, deleteSpaces } from '@utils/Text';
import { useAppSelector } from '@hooks/redux';
import { RootState } from '@redux/rootReducer';
import { filterContractsByTypeContributor } from '@utils/Options';
import { IOptionSelect } from '@components/input';

/**
 * Function to change values from contract term
 * @param {IInitEmployee} values - Values initial employee
 * @param {string} contract_type_id - Id contract type
 */
export const useChangeValues = (values: IInitEmployee, contract_type_id: string): void => {
    const { validateContractTerm } = useValidate();
    const { setFieldValue } = useFormikContext();

    useEffect(() => {
        if (values.contract_type_id) {
            if (!validateContractTerm(contract_type_id)) {
                setFieldValue(CONTRACT_TERM_ID, values.contract_term_id || '');
            } else {
                const id = validateContractTerm(contract_type_id);
                if (id && typeof id === STRING) {
                    setFieldValue(CONTRACT_TERM_ID, id);
                }
            }
        }
    }, [values.contract_type_id]);
};

/**
 * Function to capitalize name employee
 * @param {IInitEmployee} values - Values initial employee
 */
export const useChangeNameEmployee = (values: IInitEmployee): void => {
    const { setFieldValue } = useFormikContext();

    useEffect(() => {
        values.names && setFieldValue(NAME, capitalizeFirstLetter(deleteSpaces(values.names)));
        values.second_name && setFieldValue(SECOND_NAME, capitalizeFirstLetter(deleteSpaces(values.second_name)));
        values.last_names && setFieldValue(LAST_NAME, capitalizeFirstLetter(deleteSpaces(values.last_names)));
        values.second_last_name && setFieldValue(SECOND_LAST_NAME, capitalizeFirstLetter(deleteSpaces(values.second_last_name)));
    }, [values.names, values.second_name, values.last_names, values.second_last_name]);
};

/**
 * Function to change finish date value
 */
export const useChangeDetail = (): void => {
    const { setFieldValue } = useFormikContext();
    const { departmentId, positionId, costCenterId } = useCreateDetails();

    useEffect(() => {
        departmentId && setFieldValue(DEPARTMENT_ID, departmentId);
    }, [departmentId]);

    useEffect(() => {
        positionId && setFieldValue(POSITION_ID, positionId);
    }, [positionId]);

    useEffect(() => {
        costCenterId && setFieldValue(COST_CENTER_ID, costCenterId);
    }, [costCenterId]);
};

/**
 * Function to change contract term by finish date
 * @param {IInitEmployee} values - initial values employee
 */
export const useChangeFinishDate = (
    values: IInitEmployee
): {
    validateFinishDate: (finishDate: Date) => void;
} => {
    const { setFieldValue } = useFormikContext();
    const { validateSelectionContractTerm } = useValidate();

    useEffect(() => {
        if (values.contract_term_id) {
            if (validateSelectionContractTerm(values.contract_term_id)) {
                setFieldValue(FINISH_DATE, '');
            }
        }
    }, [values.contract_term_id]);

    const validateFinishDate = (finishDate: Date): void => {
        if (values.start_date_new_salary && new Date(values.start_date_new_salary) > finishDate) {
            setFieldValue(NEW_SALARY_DATE, '');
        }
    };

    return {
        validateFinishDate,
    };
};

/**
 * Function to change start date contract
 * @param {IInitEmployee} values - initial values employee
 */
export const useChangeStartDate = (
    values: IInitEmployee
): {
    validateStratDate: (startDate: Date) => void;
} => {
    const { setFieldValue } = useFormikContext();

    const validateStratDate = (startDate: Date): void => {
        if (values.start_date_new_salary && new Date(values.start_date_new_salary) < startDate) {
            setFieldValue(NEW_SALARY_DATE, '');
        }
    };

    return {
        validateStratDate,
    };
};

/**
 * Function to change transportation assistance
 * @param {IInitEmployee} values - initial values employee
 */
export const useChangeTransportationAssistance = (): {
    validateAssistance: (value: string) => void;
    requireAssistance: (value: string) => boolean;
} => {
    const {
        utils: { integral_minimum_wage },
    } = useAppSelector((state: RootState) => state);
    const { setFieldValue } = useFormikContext();
    const salaryAssistance = integral_minimum_wage * 2;

    const validateAssistance = (value: string): void => {
        const currentValue = parseValue(value || '');

        setFieldValue(TRANSPORTATION_ASSISTANCE, currentValue <= salaryAssistance);
    };

    const requireAssistance = (value: string): boolean => {
        const currentValue = parseValue(value || '');
        return currentValue <= salaryAssistance && currentValue !== 0;
    };

    return {
        validateAssistance,
        requireAssistance,
    };
};

export const useChangeNewSalaryDate = (): {
    removeValue: (permanentSalary: boolean) => void;
    setValue: (permanentSalary: boolean) => void;
} => {
    const { setFieldValue, setFieldTouched } = useFormikContext();

    const removeValue = (permanentSalary: boolean): void => {
        if (!permanentSalary) {
            setFieldValue(NEW_SALARY_DATE, '');
            setFieldTouched(NEW_SALARY_DATE, false);
        }
    };

    const setValue = (permanentSalary: boolean): void => {
        setFieldValue(PERMANENT_SALARY_CHANGE, permanentSalary);
    };

    return {
        removeValue,
        setValue,
    };
};

export const useCheckContract = (contractTypeId: string): { checkUniversityContract: boolean } => {
    const checkUniversityContract = ((contractTypeId: string): boolean => {
        return [contractSENA, contractUniversityPractice].some(item => item === contractTypeId);
    })(contractTypeId);

    return {
        checkUniversityContract,
    };
};

export const useChangePermanentSalary = (): {
    returnSalary: (value: string) => void;
    updateOldSalary: (value: string) => void;
} => {
    const { setFieldValue } = useFormikContext();

    const returnSalary = (value: string): void => {
        setFieldValue('salary', value);
    };

    const updateOldSalary = (value: string): void => {
        setFieldValue('old_salary', value);
    };

    return {
        returnSalary,
        updateOldSalary,
    };
};

export const useChangeCompensationBox = (): {
    validateCompensationBoxByTypeFunder: (value: string) => void;
} => {
    const { setFieldValue } = useFormikContext();
    const validateCompensationBoxByTypeFunder = (value: string): void => {
        if (value === TAXPAYER_DEPENDENT) return;

        setFieldValue(StepTwoValues.COMPENSATION_BOX_ID, '');
    };

    return {
        validateCompensationBoxByTypeFunder,
    };
};

export const useChangeTypeContract = (
    type_funder_id: string,
    contracts: IOptionSelect[]
): {
    validateChangeTypeContract: (type_funder_id: string, contracts: IOptionSelect[]) => void;
} => {
    useEffect(() => {
        validateChangeTypeContract();
    }, [type_funder_id]);
    const { setFieldValue } = useFormikContext();

    const validateChangeTypeContract = (): void => {
        const contractsValidByTypeFunder = filterContractsByTypeContributor(type_funder_id, contracts);
        setFieldValue('contract_type_id', contractsValidByTypeFunder.at(0)?.value);
    };
    return {
        validateChangeTypeContract,
    };
};

export const useChangeSalaryEndowment = (): {
    validateSalaryEndowment: (value: string) => void;
} => {
    const { setFieldValue } = useFormikContext();
    const validateSalaryEndowment = (value: string): void => {
        if (value === YES_SPANISH) return;

        setFieldValue('salary_endowment_value', '');
    };

    return {
        validateSalaryEndowment,
    };
};

export const useValidateIntegralSalaryDateRequired = (salary_type_id: string): { integralSalaryDate: boolean } => {
    const integralSalaryDate = ((salary_type_id: string): boolean => {
        return salary_type_id === SalaryTypesId.FULL_SALARY;
    })(salary_type_id);

    return {
        integralSalaryDate,
    };
};
