// Redux
import { AppThunk } from '@redux/configureStore';
// Libraries
import { createSlice } from '@reduxjs/toolkit';
import { AccountInfo } from '@azure/msal-browser';
// Models
import { ISessionState } from './types';
// Utils
import localStorage from '@utils/LocalStorage';
// Constants
import { LoginConstants, SESSION_INACTIVE_STATE } from '@constants/Session';

const initialState: ISessionState = {
    error: '',
    login: false,
    accessToken: '',
    user: null,
    dataCompany: false,
    guideTour: false,
    loginUser: null,
    rol: '',
    activeTour: false,
    inactive: false,
};

const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        setSession: (state, action) => {
            localStorage.set(LoginConstants.USER_TOKEN, action?.payload?.token);
            localStorage.setObject(LoginConstants.USER_DATA, action?.payload?.data);
            localStorage.set('INACTIVE', SESSION_INACTIVE_STATE.SESSION_ACTIVATED);
            state.accessToken = action?.payload?.token;
            state.user = action.payload?.data;
        },
        failedSession: (state, action) => {
            state.error = action.payload;
        },
        loginSession: (state, action) => {
            state.login = action.payload;
        },
        setDataCompany: (state, action) => {
            state.dataCompany = action.payload;
        },
        setGuideTour: (state, action) => {
            state.guideTour = action.payload;
        },
        setLoginUser: (state, action) => {
            state.loginUser = action.payload;
        },
        setRol: (state, action) => {
            state.rol = action.payload;
        },
        setActiveTour: (state, action) => {
            state.activeTour = action.payload;
        },
        clearSession: state => {
            localStorage.clearKey(LoginConstants.USER_TOKEN);
            localStorage.clearKey(LoginConstants.USER_DATA);
            localStorage.clearKey(LoginConstants.EMPLOYER_ID);
            localStorage.clearKey(LoginConstants.USER_ID);
            state.accessToken = '';
            state.user = null;
            state.dataCompany = false;
        },
        setInactive: (state, action) => {
            localStorage.set('INACTIVE', SESSION_INACTIVE_STATE.SESSION_EXPIRED);
            state.inactive = action.payload;
        },
    },
});

/**
 * Action to login user
 * @constructor
 * @param {AccountInfo} activeAccount - data current login user
 */
export const handleLogin = (token: string, activeAccount: AccountInfo): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            if (activeAccount) {
                dispatch(setSession({ token: token, data: activeAccount }));
            }
        } catch (error) {
            dispatch(failedSession('*Login failed'));
        }
    };
};

export const {
    setSession,
    setDataCompany,
    failedSession,
    clearSession,
    setGuideTour,
    setLoginUser,
    loginSession,
    setRol,
    setActiveTour,
    setInactive,
} = sessionSlice.actions;

export default sessionSlice.reducer;
