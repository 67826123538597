// Libraries
import { AppThunk } from '@redux/configureStore';
// Internal imports
import { createSlice } from '@reduxjs/toolkit';
// Types
import { IFormValuesLiquidationContract, IResumeLiquidationState, TgetOrCreateContractLiquidationResponse } from './types';
// Api
import { apiPostContractLiquidation } from '@api/resumeLiquidation';
// Models
import { IGetOrCreateContractLiquidation } from '@models/ResumeLiquidation';
import { IPaymentValues } from '@pages/payroll-adjuster/models/PaymentValues';
// Pages
import { INITIAL_VALUES_LIQUIDATION_CONTRACT, liquidationMessages } from '@pages/resume-liquidation/constants/ResumeLiquidation';
// Redux
import { putUpdateEmployee } from '@redux/employee/employee.slice';

export const formValuesLiquidationContractInitial: IFormValuesLiquidationContract = {
    end_date: new Date(),
    liquidation_reason_id: '',
    salary_base: '',
    vacation_days: '',
};

const initialState: IResumeLiquidationState = {
    contractLiquidationInfo: INITIAL_VALUES_LIQUIDATION_CONTRACT,
    errorLiquidationContract: '',
    employeesLiquidationContract: [],
    showModalSuperposition: false,
    error: '',
    showModalSuperpositionContract: false,
    errorSuperposition: '',
    formValuesLiquidationContract: formValuesLiquidationContractInitial,
};

const resumeLiquidationSlice = createSlice({
    name: 'resumeLiquidation',
    initialState,
    reducers: {
        setGetOrCreateContractLiquidation: (state, action) => {
            state.contractLiquidationInfo = action.payload || null;
        },
        setGetErrorLiquidationContract: (state, action) => {
            state.errorLiquidationContract = action.payload || '';
        },
        setAlreadySaved: (state, action) => {
            state.employeesLiquidationContract = [...state.employeesLiquidationContract, action.payload];
        },
        setShowModalSuperposition: (state, action) => {
            state.showModalSuperposition = action.payload;
        },
        setShowModalSuperpositionContract: (state, action) => {
            state.showModalSuperpositionContract = action.payload;
        },
        setFormValuesLiquidationContract: (state, action) => {
            state.formValuesLiquidationContract = action.payload;
        },
        setGetErrorSuperposition: (state, action) => {
            state.errorSuperposition = action.payload || '';
        },
        failedRequest: (state, action) => {
            state.error = action.payload;
        },
    },
});

export const getOrCreateContractLiquidation = (
    data: IGetOrCreateContractLiquidation,
    paymentData: IPaymentValues | null = {
        account_number: '',
        payment_method_id: '',
        bank_id: '',
        bank_account_type_id: '',
    }
): AppThunk => {
    return async (dispatch, getState): Promise<TgetOrCreateContractLiquidationResponse> => {
        const {
            payrollAdjuster: { currentEmployee },
        } = getState();
        try {
            const { employee_id, is_save } = data;

            const response = (await apiPostContractLiquidation({ ...data })) as unknown as {
                message: string;
                is_successful: boolean;
            };

            if (response?.message) {
                dispatch(setGetErrorLiquidationContract(response?.message));
            } else {
                dispatch(setGetErrorLiquidationContract(''));
                dispatch(setGetOrCreateContractLiquidation(response?.message || response));

                if (is_save) {
                    const data = {
                        employee: {
                            id: currentEmployee?.id,
                            ...paymentData,
                            bank_account_type_id: paymentData?.bank_account_type_id,
                        },
                        update_pila: false,
                    };

                    dispatch(putUpdateEmployee(currentEmployee?.id, data, true, (): void => {}));
                    dispatch(setAlreadySaved(employee_id));
                }
            }

            if (!response.is_successful) {
                dispatch(setShowModalSuperposition(true));
            }
            return response;
        } catch (error) {
            dispatch(failedRequest(liquidationMessages.errorRequestData));
            return { message: liquidationMessages.errorRequestData, is_successful: false };
        }
    };
};

export const {
    setGetOrCreateContractLiquidation,
    setGetErrorLiquidationContract,
    setAlreadySaved,
    setShowModalSuperposition,
    failedRequest,
    setShowModalSuperpositionContract,
    setFormValuesLiquidationContract,
    setGetErrorSuperposition,
} = resumeLiquidationSlice.actions;

export default resumeLiquidationSlice.reducer;
